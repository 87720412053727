import React, { useEffect } from "react";
import { Card, Button, Typography, Row, Col, Spin, Alert } from "antd";
import { LeftOutlined, MessageOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useGetUserByIdQuery } from "../../../RtkQuery/users/usersApi";
import "./index.css";

const { Title, Text } = Typography;

const PARequestDeatailsPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const formStatus = localStorage.getItem("formStatus");
  useEffect(() => {
    if (formStatus !== "approved") {
      navigate("/concierge_dashboard");
    }
  }, [formStatus]);

  const { data, isLoading, refetch, error } = useGetUserByIdQuery(id, {
    refetchOnMountOrArgChange: true,
  });
  // const user = JSON.parse(localStorage.getItem('user')) || {}

  const startChat = (id) => {
    navigate(`/peerAmbassador_chats/${id}`);
  };

  if (error) {
    return (
      <Alert
        message="Error"
        description="Failed to load request details"
        type="error"
      />
    );
  }

  // Handle data structure once fetched
  // const requestDetails = data || {} /

  return (
    <div className="details-container">
      {/* Back Button */}
      <Button
        className="back-button"
        icon={<LeftOutlined />}
        onClick={() => navigate(-1)}
      >
        Back
      </Button>

      {isLoading ? (
        <Spin
          tip="Loading..."
          style={{ display: "block", marginTop: "16px" }}
        />
      ) : (
        <Card
          title={<Title level={4}>Operator Details</Title>}
          className="details-card"
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Text strong>Name: </Text>
              <Text>{data?.firstName + " " + data?.lastName}</Text>
            </Col>
            <Col xs={24} sm={12}>
              <Text strong>Email: </Text>
              <Text>{data?.email}</Text>
            </Col>
            <Col xs={24} sm={12}>
              <Text strong>Phone: </Text>
              <Text>{data?.phoneNumber}</Text>
            </Col>
          </Row>
          <Button
            type="primary"
            icon={<MessageOutlined />}
            style={{ float: "right" }}
            onClick={() => startChat(data?._id)}
          >
            Chat
          </Button>
        </Card>
      )}
    </div>
  );
};

export default PARequestDeatailsPage;
