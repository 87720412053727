import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Table, Button, Row, Col, Typography, Spin, message } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { LeftOutlined, MessageOutlined } from "@ant-design/icons";
import "./index.css";
import { useGetUserByIdQuery } from "../../../RtkQuery/users/usersApi";
import {
  useGetOperatorRequestHistoryQuery,
  useGetOperatorsQuery,
} from "../../../RtkQuery/ServiceRequest";
import {
  useAssignStaffToOperatorMutation,
  useUnAssignStaffToOperatorMutation,
} from "../../../RtkQuery/users/usersApi";
import dayjs from "dayjs";
import DualListCard from "../../../Components/UIKit/DualListCard";
import AssignCardList from "../../../Components/UIKit/PersonnelList";

const AdminOperatorDetails = () => {
  const navigate = useNavigate();
  const [conciergeUsers, setConciergeUsers] = useState([]);
  const [peerAmbassadorUsers, setPeerAmbassadorUsers] = useState([]);
  const { Title, Text } = Typography;
  const user = JSON.parse(localStorage.getItem("user")) || {};

  const { id } = useParams();
  const { data, isLoading, refetch } = useGetUserByIdQuery(id, {
    refetchOnMountOrArgChange: true,
  });
  const { data: requests, isLoading: reqLoading } =
    useGetOperatorRequestHistoryQuery(id);
  console.log(requests);

  const startChat = (id) => {
    navigate(`/admin_chats/${id}`);
  };

  const [assignStaffToOperator, { isLoading: assigningStaff }] =
    useAssignStaffToOperatorMutation();

  const [unAssignStaffToOperator, { isLoading: unAssigningStaff }] =
    useUnAssignStaffToOperatorMutation();

  //get list of users to assign
  // Fetch data with pagination
  const {
    data: concierge,
    error: conError,
    isLoading: conLoading,
  } = useGetOperatorsQuery({
    page: 1,
    limit: 10,
  });
  // Assign unassign states
  const [assignedConcierge, setAssignedConcierge] = useState(
    data?.assignedConcierge || []
  );
  const [assignedAmbassador, setAssignedAmbassador] = useState(
    data?.assignedPeerAmbassador || []
  );

  const handleAssignStaff = async (conId, paId) => {
    const payload = {
      conciergeIds: conId !== null ? [conId] : [],
      peerAmbassadorIds: paId !== null ? [paId] : [],
      intake: user._id,
    };
    try {
      const response = await assignStaffToOperator({ id, ...payload });
      if (response) {
        message.success("Staff successfully assigned!");
        refetch(); // Refresh the request details
      }
    } catch (error) {
      message.error("Failed to assign staff. Please try again.");
    }
  };

  const handleUnAssignStaff = async (conId, paId) => {
    const payload = {
      conciergeIds: conId !== null ? [conId] : [],
      peerAmbassadorIds: paId !== null ? [paId] : [],
      intake: user._id,
    };
    try {
      const response = await unAssignStaffToOperator({ id, ...payload });
      if (response) {
        message.success("Staff successfully unassigned!");
        refetch(); // Refresh the request details
      }
    } catch (error) {
      message.error("Failed to unassign staff. Please try again.");
    }
  };

  // Table columns for request history
  const columns = [
    {
      title: "Support Type",
      dataIndex: "supportType",
      key: "supportType",
    },
    {
      title: "Healty Support Type",
      dataIndex: "mentalHealthSupportType",
      key: "mentalHealthSupportType",
    },
    {
      title: "Request Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => dayjs(createdAt).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button
          type="primary"
          icon={<EyeOutlined />}
          onClick={() => navigate(`/admin_requestDetails/${record._id}`)}
        >
          View Details
        </Button>
      ),
    },
  ];

  useEffect(() => {
    if (concierge) {
      // Extract assigned IDs from objects
      const assignedConciergeIds = (data?.assignedConcierge || []).map(
        (user) => user._id
      );
      const assignedPeerAmbassadorIds = (
        data?.assignedPeerAmbassador || []
      ).map((user) => user._id);

      // Filter out assigned users
      const conciergeFiltered = concierge.filter(
        (user) =>
          user.role === "concierge" && !assignedConciergeIds.includes(user._id)
      );

      const peerAmbassadorFiltered = concierge.filter(
        (user) =>
          user.role === "peerAmbassador" &&
          !assignedPeerAmbassadorIds.includes(user._id)
      );

      // Update state
      setConciergeUsers(conciergeFiltered);
      setPeerAmbassadorUsers(peerAmbassadorFiltered);
    }
  }, [concierge, data?.assignedConcierge, data?.assignedPeerAmbassador]);

  // Filter out already assigned users
  const unassignedConciergeUsers = conciergeUsers.filter(
    (user) => !assignedConcierge.includes(user._id)
  );
  const unassignedPeerAmbassadorUsers = peerAmbassadorUsers.filter(
    (user) => !assignedAmbassador.includes(user._id)
  );

  // Role mapping object
  const roleMapping = {
    admin: "Admin",
    peerAmbassador: "Peer Ambassador",
    concierge: "Concierge",
    operator: "Operator",
    intakeCare: "Intake",
  };

  return (
    <div className="int-operator-details-container">
      {/* Top Navigation */}
      <div className="header">
        <Button icon={<LeftOutlined />} onClick={() => navigate(-1)}>
          Back
        </Button>
      </div>
      {/* Operator Details Card */}
      {isLoading ? (
        <Spin
          tip="Loading..."
          style={{ display: "block", marginTop: "16px" }}
        />
      ) : (
        <Card
          title={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Title level={4} style={{ margin: "0.5rem 0" }}>
                User Details
              </Title>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button
                  type="primary"
                  icon={<MessageOutlined />}
                  style={{ marginRight: "1rem" }}
                  onClick={() => navigate(`/admin_chats/${data._id}`)}
                >
                  Chat
                </Button>
                {data?.role === "operator" && (
                  <Button
                    type="primary"
                    onClick={() => navigate(`/notes/${id}?type=operator`)}
                  >
                    View Notes
                  </Button>
                )}
              </div>
            </div>
          }
          className="details-card"
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={8}>
              <Text strong>Name: </Text>
              <Text>{data?.firstName + " " + data?.lastName}</Text>
            </Col>
            <Col xs={24} sm={8}>
              <Text strong>Phone Number: </Text>
              <Text>{data.phoneNumber}</Text>
            </Col>
            <Col xs={24} sm={8}>
              <Text strong>Email: </Text>
              <Text>{data.email}</Text>
            </Col>
            <Col xs={24} sm={8}>
              <Text strong>Level: </Text>
              <Text>{data.levelOfOperator}</Text>
            </Col>

            <Col xs={24} sm={8}>
              <Text strong>Role: </Text>
              <Text>{roleMapping[data.role] || data.role}</Text>
            </Col>
            <Col xs={24} sm={8}>
              <Text strong>Branch: </Text>
              <Text>{data?.branch}</Text>
            </Col>
            <Col xs={24} sm={8}>
              <Text strong>State: </Text>
              <Text>{data.state}</Text>
            </Col>
            <Col xs={24} sm={8}>
              <Text strong>City: </Text>
              <Text>{data.city}</Text>
            </Col>
          </Row>
          <Row style={{ marginTop: "1rem" }}>
            <Col span={24}>
              <Text strong>About: </Text>
              <p>{data.bio}</p>
            </Col>
          </Row>
        </Card>
      )}

      {data?.role === "operator" && (
        <div>
          <DualListCard
            listOne={data?.assignedConcierge}
            listTwo={data?.assignedPeerAmbassador}
            titleOne={"Assigned Concierge"}
            titleTwo={"Assigned Peer Ambassador"}
            chatUrl={"/admin_chats"}
            onUnAssign={handleUnAssignStaff}
          />

          {/* Assign concierge and peerAmbassadors */}
          {data?.approvedStatus === "approved" && (
            <Row gutter={[16, 16]} className="assignment-section">
              <Col xs={24} sm={12}>
                {conLoading ? (
                  <Spin />
                ) : (
                  <AssignCardList
                    title="Assign Concierge"
                    items={conciergeUsers}
                    loading={assigningStaff}
                    onAssign={handleAssignStaff}
                    isConciergeList={true}
                  />
                )}
              </Col>
              <Col xs={24} sm={12}>
                {conLoading ? (
                  <Spin />
                ) : (
                  <AssignCardList
                    title="Assign Peer Ambassador"
                    items={peerAmbassadorUsers}
                    onAssign={handleAssignStaff}
                    loading={assigningStaff}
                  />
                )}
              </Col>
            </Row>
          )}
        </div>
      )}

      {/* Request History Table */}
      {requests && requests.length > 0 && (
        <>
          <Title level={4}>Request History</Title>
          {reqLoading ? (
            <Spin
              tip="Loading..."
              style={{ display: "block", marginTop: "16px" }}
            />
          ) : (
            <Table
              style={{ border: "2px solid #f0f0f0", borderRadius: "10px" }}
              columns={columns}
              dataSource={requests}
              rowKey="id"
              pagination={{ pageSize: 5 }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default AdminOperatorDetails;
