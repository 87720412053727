import React, { useState, useEffect } from "react";
import { Button, Typography, Layout, Divider, notification } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  useGetReqNoteByIdQuery,
  useCreateNoteMutation,
  useUpdateNoteMutation,
  useGetNoteByOperatorIdQuery,
} from "../../../RtkQuery/notes/notesApi";
import SanitizedHTML from "../../../Components/SanitizeHtml";

const { Content } = Layout;

const NotesPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const operator = searchParams.get("type"); // Get the query param type

  // State for note data
  const [title, setTitle] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  // Fetch data based on query param
  const { data: reqNote, isLoading: isReqFetching } = useGetReqNoteByIdQuery(
    id,
    {
      skip: operator !== "request",
      refetchOnMountOrArgChange: true,
    }
  );
  const { data: opNote, isLoading: isOpFetching } = useGetNoteByOperatorIdQuery(
    id,
    {
      skip: operator !== "operator",
      refetchOnMountOrArgChange: true,
    }
  );

  const [createNote, { isLoading: isCreating }] = useCreateNoteMutation();
  const [updateNote, { isLoading: isUpdating }] = useUpdateNoteMutation();

  const note = operator === "request" ? reqNote : opNote;
  const isFetching = operator === "request" ? isReqFetching : isOpFetching;

  // Handle fetched note data
  useEffect(() => {
    if (note && note.length > 0) {
      setTitle(note[0]?.title || "");
      setEditorContent(note[0]?.content || "");
      setIsEditing(false);
    } else {
      // Empty note scenario
      setTitle("");
      setEditorContent("");
      setIsEditing(true); // Automatically switch to edit mode
    }
  }, [note]);

  const handleSave = async () => {
    try {
      if (isEditing && note?.length > 0) {
        // Update existing note
        await updateNote({
          id: note[0]._id,
          title,
          content: editorContent,
        }).unwrap();
        notification.success({ message: "Note updated successfully!" });
      } else {
        // Create new note
        await createNote({
          [operator === "operator" ? "operatorId" : "requestId"]: id,
          title,
          content: editorContent,
        }).unwrap();
        notification.success({ message: "Note created successfully!" });
      }
      setIsEditing(false);
    } catch (error) {
      notification.error({
        message: "Failed to save note.",
        description: error.message,
      });
    }
  };

  const handleCancel = () => {
    if (note && note.length > 0) {
      setTitle(note[0]?.title || "");
      setEditorContent(note[0]?.content || "");
    } else {
      setTitle("");
      setEditorContent("");
    }
    setIsEditing(false);
  };

  return (
    <div
      style={{
        background: "#fff",
        padding: "16px",
        borderRadius: "8px",
      }}
    >
      <Button icon={<ArrowLeftOutlined />} onClick={() => navigate(-1)}>
        Back
      </Button>

      <Typography.Title level={2}>{title || "Create Note"}</Typography.Title>
      <Divider />

      {isFetching ? (
        <Typography.Text>Loading note...</Typography.Text>
      ) : (
        <div>
          {isEditing && (
            <div style={{ marginBottom: "20px" }}>
              <Typography.Text>Title</Typography.Text>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Enter title"
                style={{
                  width: "100%",
                  padding: "8px",
                  margin: "10px 0",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                }}
              />
            </div>
          )}

          {isEditing ? (
            <div
              style={{
                minHeight: "60vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <ReactQuill
                value={editorContent}
                onChange={setEditorContent}
                className="text-editor"
                style={{ height: "300px", marginBottom: "20px" }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                  margin: "1rem 0",
                }}
              >
                <Button
                  type="primary"
                  onClick={handleSave}
                  loading={isCreating || isUpdating}
                >
                  Save
                </Button>
                <Button onClick={handleCancel}>Cancel</Button>
              </div>
            </div>
          ) : (
            <div style={{ marginTop: "20px" }}>
              <Typography.Paragraph>
                <SanitizedHTML html={editorContent || "No content available"} />
              </Typography.Paragraph>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button type="primary" onClick={() => setIsEditing(true)}>
                  Edit
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default NotesPage;
