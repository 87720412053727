import React, { useEffect, useState } from "react";
import { Typography, Divider, Button, Spin, Table, Tag } from "antd";
import DynamicTable from "../../../Components/UIKit/Table";
import { useNavigate } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import { useGetRequestsQuery } from "../../../RtkQuery/ServiceRequest";
import dayjs from "dayjs";
import SearchBar from "../../../Components/UIKit/SearchBar";
import ReviewMessage from "../../../Components/WaitingScreens/formUnderReview";
import img from "../../../assets/Images/underReview2.png";
import ConFirstLoginModal from "../../../Components/Modals/ApplicationForms/concierge";

const { Title } = Typography;

const ConciergeDashboard = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [refetch, setRefetch] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [formStatus, setFormStatus] = useState(
    localStorage.getItem("formStatus")
  );
  const { data, error, isLoading } = useGetRequestsQuery(
    {
      page: pagination.current,
      limit: pagination.pageSize,
      search: searchTerm,
    },
    { refetchOnMountOrArgChange: true }
  );

  // Columns for the table
  const columns = [
    {
      title: "Support Type",
      dataIndex: "supportType",
      key: "supportType",
    },
    {
      title: "Health Support Type",
      dataIndex: "mentalHealthSupportType",
      key: "mentalHealthSupportType",
    },
    {
      title: "Requestee Name",
      key: "requesteeName",
      render: (_, record) =>
        `${record.operator?.firstName || ""} ${
          record.operator?.lastName || ""
        }`.trim(),
    },
    {
      title: "Request Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => dayjs(createdAt).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "Status",
      dataIndex: "approvedStatus",
      key: "approvedStatus",
      render: (_, record) => (
        <Tag
          style={{
            color:
              record.approvedStatus === "pending" || record.status === "pending"
                ? "orange"
                : record.approvedStatus === "approved" ||
                  record.status === "completed"
                ? "green"
                : "red",
          }}
        >
          {record.approvedStatus === "pending"
            ? "Pending"
            : record.approvedStatus === "approved" &&
              record.status === "pending"
            ? "In progress"
            : record.status === "completed"
            ? "Completed"
            : "Rejected"}
        </Tag>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <div style={{ display: "flex", gap: "1rem" }}>
          <Button
            type="primary"
            icon={<EyeOutlined />}
            onClick={() => navigate(`/concierge/request/${record._id}`)}
          >
            View Details
          </Button>
        </div>
      ),
    },
  ];

  const handleTableChange = (pagination) => {
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  useEffect(() => {
    setFormStatus(localStorage.getItem("formStatus"));
  }, [formStatus, refetch]);

  return (
    <div style={{ padding: "16px" }}>
      {formStatus === "formSubmitted" ? (
        <div>
          <ReviewMessage
            message={
              "Please wait, your request is under review and you will be notified shortly"
            }
            imageSrc={img}
          />
        </div>
      ) : formStatus === "rejected" ? (
        <div style={{fontSize:'22px', color:'red',margin:'5rem 10rem', backgroundColor:'white',padding:'5%', borderRadius:"5px"}}>
          Your signup request has been reviewed and unfortunately, it has been
          rejected. If you have any questions or need further assistance, please
          contact our support team.
        </div>
      ) : formStatus === "approved" ? (
        <div>
          <Title level={2}>Dashboard</Title>
          <Divider />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              marginBottom: "24px",
            }}
          >
            <div>
              <h3>Requests</h3>
            </div>
            <div>
              <SearchBar
                placeholder="Search requests..."
                onSearch={(term) => setSearchTerm(term)}
              />
            </div>
          </div>
          {isLoading ? (
            <Spin
              tip="Loading..."
              style={{ display: "block", marginTop: "16px" }}
            />
          ) : (
            <Table
              style={{ border: "2px solid #f0f0f0", borderRadius: "10px" }}
              columns={columns}
              dataSource={data?.data}
              pagination={{
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: data?.total || 0,
              }}
              onChange={handleTableChange}
            />
          )}
        </div>
      ) : (
        <div>
          Fill the form to get acces to the features
          <ConFirstLoginModal setState={setRefetch} />
        </div>
      )}
    </div>
  );
};

export default ConciergeDashboard;
