import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./index.css";
import { useSignupMutation } from "../../RtkQuery/auth/auth";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons

const SignUp = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phoneNumber: "",
    role: "",
    levelOfOperator: "",
    branch: "",
    transitionDate: "",
    city: "",
    state: "",
    bio: "", // Add bio to formData
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const navigate = useNavigate();
  const location = useLocation(); // Get the location object to extract query params

  const [signup, { isLoading, isError, error }] = useSignupMutation();

  // Extract the 'type' query parameter (e.g., ?type=operator)
  const queryParams = new URLSearchParams(location.search);
  const roleFromQuery = queryParams.get('type'); // Get the value of 'type'

  // Set the role default value based on the query param, if present
  useEffect(() => {
    if (roleFromQuery) {
      setFormData((prev) => ({
        ...prev,
        role: roleFromQuery,
      }));
    }
  }, [roleFromQuery]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validateForm = () => {
    const newErrors = {};
    Object.keys(formData).forEach((field) => {
      if (!formData[field] && field !== "transitionDate") {
        newErrors[field] = "This field is required";
      }
    });
    if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email address";
    }
    if (formData.password && formData.password.length < 8) {
      newErrors.password = "Password must be at least 8 characters long";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsSubmitting(true);
    try {
      const response = await signup(formData).unwrap();
      if (response) {
        console.log(response);
        navigate("/signup_success");
      }
    } catch (err) {
      setErrors({ api: error.data.message || "Something went wrong" });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="auth-container">
      <h1 className="auth-title">Sign Up</h1>
      <form className="auth-form" onSubmit={handleSubmit}>
        {errors.api && <p className="error">{errors.api}</p>}
        <input
          type="text"
          name="firstName"
          placeholder="First Name"
          className="auth-input"
          value={formData.firstName}
          onChange={handleChange}
        />
        {errors.firstName && <p className="error">{errors.firstName}</p>}

        <input
          type="text"
          name="lastName"
          placeholder="Last Name"
          className="auth-input"
          value={formData.lastName}
          onChange={handleChange}
        />
        {errors.lastName && <p className="error">{errors.lastName}</p>}

        <input
          type="email"
          name="email"
          placeholder="Email Address"
          className="auth-input"
          value={formData.email}
          onChange={handleChange}
        />
        {errors.email && <p className="error">{errors.email}</p>}

        <input
          type="text"
          name="phoneNumber"
          placeholder="Phone Number"
          className="auth-input"
          value={formData.phoneNumber}
          onChange={handleChange}
        />
        {errors.phoneNumber && <p className="error">{errors.phoneNumber}</p>}

        <div className="password-container" style={{ position: "relative" }}>
          <input
            style={{ width: "93%" }}
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder="Password"
            className="auth-input"
            value={formData.password}
            onChange={handleChange}
          />
          <span
            style={{ position: "absolute", bottom: "20%", right: "3%" }}
            onClick={() => setShowPassword(!showPassword)}
            className="password-toggle"
          >
            {showPassword ? (
              <FaEyeSlash style={{ color: "gray" }} />
            ) : (
              <FaEye style={{ color: "gray" }} />
            )}
          </span>
        </div>
        {errors.password && <p className="error">{errors.password}</p>}

        <select
          name="role"
          value={formData.role}
          onChange={handleChange}
          className="auth-input"
        >
          <option value="" disabled>
            Select Your Role
          </option>
          <option value="operator">Operator</option>
          <option value="concierge">Concierge</option>
          <option value="peerAmbassador">Peer Ambassador</option>
          <option value="servicePartner">Service Partner</option>
        </select>
        {errors.role && <p className="error">{errors.role}</p>}

        <input
          type="text"
          name="levelOfOperator"
          placeholder="Level of Operator"
          className="auth-input"
          value={formData.levelOfOperator}
          onChange={handleChange}
        />
        {errors.levelOfOperator && (
          <p className="error">{errors.levelOfOperator}</p>
        )}

        <input
          type="text"
          name="branch"
          placeholder="Branch"
          className="auth-input"
          value={formData.branch}
          onChange={handleChange}
        />
        {errors.branch && <p className="error">{errors.branch}</p>}
        <label htmlFor="transitionDate">Transition Date :</label>
        <input
          type="date"
          name="transitionDate"
          placeholder="transition date"
          className="auth-input"
          value={formData.transitionDate}
          onChange={handleChange}
        />

        <input
          type="text"
          name="city"
          placeholder="City"
          className="auth-input"
          value={formData.city}
          onChange={handleChange}
        />
        {errors.city && <p className="error">{errors.city}</p>}

        <input
          type="text"
          name="state"
          placeholder="State"
          className="auth-input"
          value={formData.state}
          onChange={handleChange}
        />
        {errors.state && <p className="error">{errors.state}</p>}

        <textarea
          name="bio"
          style={{ fontFamily: "Roboto,Sans-Serif" }}
          rows={4}
          placeholder="A bit about yourself..."
          className="auth-input"
          value={formData.bio}
          onChange={handleChange}
        />
        {errors.bio && <p className="error">{errors.bio}</p>}

        <button type="submit" className="auth-button" disabled={isSubmitting}>
          {isSubmitting || isLoading ? "Signing Up..." : "Sign Up"}
        </button>
      </form>
      <p className="auth-footer">
        Already have an account? <Link to="/login">Sign In</Link>
      </p>
    </div>
  );
};

export default SignUp;
