import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Button, Row, Col, Typography, Spin, message } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import "./index.css";
import { useGetUserByIdQuery } from "../../../RtkQuery/users/usersApi";
import { useUpdateApplicationMutation } from "../../../RtkQuery/ServiceRequest";

const AdminApplicationDetails = () => {
  const navigate = useNavigate();
  const { Title, Text } = Typography;

  const { id } = useParams();
  const { data, isLoading, refetch } = useGetUserByIdQuery(id);

  const [updateApplication, { isLoading: isUpdating }] =
    useUpdateApplicationMutation();

  // Function to handle approval or rejection
  const handleStatusUpdate = async (status) => {
    try {
      await updateApplication({ id, status }).unwrap();
      message.success(
        `Application ${
          status === "approved" ? "approved" : "rejected"
        } successfully.`
      );
      refetch();
      navigate(-1); // Navigate back after successful update
    } catch (error) {
      message.error("Failed to update the application status.");
    }
  };

  return (
    <div className="int-operator-details-container">
      {/* Top Navigation */}
      <div
        className="header"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button icon={<LeftOutlined />} onClick={() => navigate(-1)}>
          Back
        </Button>
        <div>
          <Button
            type="primary"
            onClick={() => handleStatusUpdate("approved")}
            style={{ marginRight: "0.5rem" }}
            loading={isUpdating}
          >
            Approve
          </Button>
          <Button
            type="primary"
            danger
            onClick={() => handleStatusUpdate("rejected")}
            loading={isUpdating}
          >
            Reject
          </Button>
        </div>
      </div>
      {/* Operator Details Card */}
      {isLoading ? (
        <Spin
          tip="Loading..."
          style={{ display: "block", marginTop: "16px" }}
        />
      ) : (
        <Card
          title={
            <Title level={4} style={{ margin: "0.5rem 0" }}>
              Applicant Details
            </Title>
          }
          className="details-card"
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={8}>
              <Text strong>Name: </Text>
              <Text>{data?.firstName + " " + data?.lastName}</Text>
            </Col>
            <Col xs={24} sm={8}>
              <Text strong>Phone Number: </Text>
              <Text>{data.phoneNumber}</Text>
            </Col>
            <Col xs={24} sm={8}>
              <Text strong>Email: </Text>
              <Text>{data.email}</Text>
            </Col>
            <Col xs={24} sm={8}>
              <Text strong>Level: </Text>
              <Text>{data.levelOfOperator}</Text>
            </Col>

            <Col xs={24} sm={8}>
              <Text strong>Role: </Text>
              <Text>{data.role}</Text>
            </Col>
            <Col xs={24} sm={8}>
              <Text strong>Branch: </Text>
              <Text>{data?.branch}</Text>
            </Col>
            <Col xs={24} sm={8}>
              <Text strong>State: </Text>
              <Text>{data.state}</Text>
            </Col>
            <Col xs={24} sm={8}>
              <Text strong>City: </Text>
              <Text>{data.city}</Text>
            </Col>
          </Row>
          <Row style={{ marginTop: "1rem" }}>
            <Col span={24}>
              <Text strong>About: </Text>
              <p>{data.bio}</p>
            </Col>
          </Row>
        </Card>
      )}
      {isLoading ? (
        <Spin
          tip="Loading..."
          style={{ display: "block", marginTop: "16px" }}
        />
      ) : data.role === "servicePartner" ? (
        <Card
          title={
            <Title level={4} style={{ margin: "0.5rem 0" }}>
              Service Partner Details
            </Title>
          }
          className="details-card"
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Text strong>Organization Name: </Text>
              <Text>{data.formDetails?.organizationName || "N/A"}</Text>
            </Col>
            <Col xs={24} sm={12}>
              <Text strong>Services Provided: </Text>
              <Text>{data.formDetails?.servicesProvided || "N/A"}</Text>
            </Col>
            <Col xs={24} sm={12}>
              <Text strong>Address: </Text>
              <Text>{data.formDetails?.organizationAddress || "N/A"}</Text>
            </Col>
            <Col xs={24} sm={12}>
              <Text strong>Phone: </Text>
              <Text>{data.formDetails?.organizationPhone || "N/A"}</Text>
            </Col>
            <Col xs={24} sm={12}>
              <Text strong>Website: </Text>
              <Text>{data.formDetails?.organizationWebsite || "N/A"}</Text>
            </Col>
            <Col xs={24} sm={12}>
              <Text strong>Point of Contact Name: </Text>
              <Text>{data.formDetails?.pointOfContactName || "N/A"}</Text>
            </Col>
            <Col xs={24} sm={12}>
              <Text strong>Point of Contact Email: </Text>
              <Text>{data.formDetails?.pointOfContactEmail || "N/A"}</Text>
            </Col>
          </Row>
          <Row style={{ marginTop: "1rem" }}>
            <Col span={24}>
              <Text strong>Partnership Description: </Text>
              <p>{data.formDetails?.partnershipDescription || "N/A"}</p>
            </Col>
          </Row>
        </Card>
      ) : (
        <Card
          title={
            <Title level={4} style={{ margin: "0.5rem 0" }}>
              Application Details
            </Title>
          }
          className="details-card"
        >
          {/* Contact Information */}
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Text strong>Name: </Text>
              <Text>{data?.formDetails?.name}</Text>
            </Col>
            <Col xs={24} sm={12}>
              <Text strong>Current Address: </Text>
              <Text>{data?.formDetails?.currentAddress}</Text>
            </Col>
            <Col xs={24} sm={12}>
              <Text strong>Phone: </Text>
              <Text>{data?.formDetails?.phone}</Text>
            </Col>
            <Col xs={24} sm={12}>
              <Text strong>Email: </Text>
              <Text>{data?.formDetails?.email}</Text>
            </Col>
            <Col xs={24} sm={12}>
              <Text strong>Preferred Contact Method: </Text>
              <Text>{data?.formDetails?.preferredContactMethod}</Text>
            </Col>
            <Col xs={24} sm={12}>
              <Text strong>Branch of Service: </Text>
              <Text>{data?.formDetails?.branchOfService}</Text>
            </Col>
          </Row>

          {/* Military Service */}
          <Row gutter={[16, 16]} style={{ marginTop: "1rem" }}>
            <Col xs={24} sm={12}>
              <Text strong>Date of Separation: </Text>
              <Text>{data?.formDetails?.dateOfSeparation}</Text>
            </Col>
            <Col span={24} sm={12}>
              <Text strong>How did you hear about us? </Text>
              <p style={{ margin: 0 }}>
                {data?.formDetails?.howDidYouHearAboutUs}
              </p>
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginTop: "1rem" }}>
            <Col xs={24} sm={12}>
              <Text strong>Monthly Hour Commitment: </Text>
              <Text>{data?.formDetails?.hoursCommitment}</Text>
            </Col>
            <Col xs={24} sm={12}>
              <Text strong>Max Operators At a Time: </Text>
              <Text>{data?.formDetails?.maxOperators}</Text>
            </Col>
          </Row>
          <Row style={{ marginTop: "1rem" }}>
            <Col span={24}>
              <Text strong>Why would you like to take this role? </Text>
              <p style={{ margin: 0 }}>
                {
                  data?.formDetails
                    ?.whyWouldYouLikeToBeAPeerAmbassadorOrIntakeCare
                }
              </p>
            </Col>
          </Row>

          {/* Transition Experience */}
          <Row style={{ marginTop: "1rem" }}>
            <Col span={24}>
              <Text strong>
                Did you work with any transition services/organizations during
                your transition, and would you recommend any of them?{" "}
              </Text>
              <p style={{ margin: 0 }}>
                {data?.formDetails?.transitionServicesUsed}
              </p>
            </Col>
          </Row>

          {/* Support and Comfort Levels */}
          <Row style={{ marginTop: "1rem" }}>
            <Col span={24}>
              <Text strong>
                What areas of transition do you feel you can best support?{" "}
              </Text>
              <p style={{ margin: 0 }}>{data?.formDetails?.areasToSupport}</p>
            </Col>
          </Row>
          <Row style={{ marginTop: "1rem" }}>
            <Col span={24}>
              <Text strong>
                What area(s)/topics are you uncomfortable with?{" "}
              </Text>
              <p style={{ margin: 0 }}>
                {data?.formDetails?.areasUncomfortable}
              </p>
            </Col>
          </Row>

          {/* Community Connections */}
          <Row style={{ marginTop: "1rem" }}>
            <Col span={24}>
              <Text strong>
                Are there any non-profits or service providers you have a
                relationship with? If yes, which ones?{" "}
              </Text>
              <p style={{ margin: 0 }}>
                {data?.formDetails?.nonProfitConnections}
              </p>
            </Col>
          </Row>
        </Card>
      )}
    </div>
  );
};

export default AdminApplicationDetails;
