import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Spin, Result, Button } from 'antd'
import { baseUrl } from '../../Apis'

const VerificationPage = () => {
  const navigate = useNavigate()
  const [status, setStatus] = useState('verifying')

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        // Extract token from the URL
        const params = new URLSearchParams(window.location.search)
        const token = params.get('token')

        if (!token) {
          throw new Error('Token not found in the URL')
        }

        // Call the verification API
        const response = await fetch(`${baseUrl}/auth/confirm/${token}`)
        console.log(response)
        if (response.ok) {
          setStatus('success')
        } else {
          const errorData = await response.json() // Try to capture error details
          console.error('Verification failed:', errorData)
          setStatus('error')
        }
      } catch (error) {
        console.error('Error during verification:', error)
        setStatus('error')
      }
    }

    verifyEmail()
  }, [])

  // Render based on status
  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      {status === 'verifying' && (
        <div>
          <Spin size='large' />
          <p>Verifying your account, please wait...</p>
        </div>
      )}
      {status === 'success' && (
        <Result
          status='success'
          title='Your account has been verified successfully!'
          extra={[
            <Button
              type='primary'
              onClick={() => navigate('/login')}
              key='login'
            >
              Go to Login
            </Button>
          ]}
        />
      )}
      {status === 'error' && (
        <Result
          status='error'
          title='Verification Failed'
          subTitle='The verification link is invalid or expired. Please try again.'
          extra={[
            <Button
              type='primary'
              onClick={() => navigate('/resend-verification')}
              key='resend'
            >
              Resend Verification Email
            </Button>
          ]}
        />
      )}
    </div>
  )
}

export default VerificationPage
