import React, { useState, useEffect } from "react";
import { Button, Divider, Spin, Tag, Table } from "antd";
import { useGetRequestsQuery } from "../../../RtkQuery/ServiceRequest";
import { EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import SearchBar from "../../../Components/UIKit/SearchBar";

const OperatorDashboard = () => {
  const [isApproved, setIsApproved] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [tableData, setTableData] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  const navigate = useNavigate();

  // Fetch approval status on component mount
  useEffect(() => {
    const approvalStatus = localStorage.getItem("approvedStatus");
    setIsApproved(approvalStatus);
  }, []);

  // Fetch requests using RTK Query with pagination and search term
  const { data, error, isLoading } = useGetRequestsQuery(
    { page: pagination.current, limit: pagination.pageSize, search: searchTerm },
    { refetchOnMountOrArgChange: true }
  );

  // Update table data when API data changes
  useEffect(() => {
    if (data) {
      setTableData(data.data);
    }
  }, [data]);

  const handleTableChange = (pagination) => {
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  // Table columns definition
  const columns = [
    {
      title: "Sr No",
      key: "srNo",
      render: (_, __, index) => (pagination.current - 1) * 10 + index + 1,
    },
    {
      title: "Support Type",
      dataIndex: "supportType",
      key: "supportType",
    },
    {
      title: "Health Support Type",
      dataIndex: "mentalHealthSupportType",
      key: "mentalHealthSupportType",
    },
    {
      title: "Request Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => dayjs(createdAt).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "Status",
      dataIndex: "approvedStatus",
      key: "approvedStatus",
      render: (_, record) => (
        <Tag
          style={{
            color:
              record.approvedStatus === "pending" || record.status === "pending"
                ? "orange"
                : record.approvedStatus === "approved" ||
                  record.status === "completed"
                ? "green"
                : "red",
          }}
        >
          {record.approvedStatus === "pending"
            ? "Pending"
            : record.approvedStatus === "approved" &&
              record.status === "pending"
            ? "In progress"
            : record.status === "completed"
            ? "Completed"
            : "Rejected"}
        </Tag>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Button
          type="primary"
          icon={<EyeOutlined />}
          onClick={() => navigate(`/operator/request/${record._id}`)}
        >
          View Details
        </Button>
      ),
    },
  ];

  return (
    <div style={{ padding: "16px" }}>
      <h1>Dashboard</h1>
      <Divider />
      {isApproved === "approved" ? (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              marginBottom: "24px",
            }}
          >
            <div>
              <h3>Requests</h3>
            </div>
            <div>
              <SearchBar
                placeholder="Search requests..."
                onSearch={(term) => {
                  setSearchTerm(term);
                  setPagination({
                    current: 1,
                    ...pagination,
                  });
                }}
              />
            </div>
          </div>
          {isLoading ? (
            <Spin
              tip="Loading..."
              style={{ display: "block", marginTop: "16px" }}
            />
          ) : error ? (
            <p style={{ color: "red" }}>
              Failed to load data. Please try again later.
            </p>
          ) : (
            <Table
              style={{ border: "2px solid #f0f0f0", borderRadius: "10px" }}
              columns={columns}
              dataSource={tableData} // Use state-based data
              rowKey="_id" // Use a unique key for rows
              pagination={{
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: data?.total || 0,
              }}
              onChange={handleTableChange}
              loading={isLoading}
            />
          )}
        </>
      ) : (
        <p style={{ color: "gray" }}>
          Your profile is under review. You will be able to use the app once you
          are approved.
        </p>
      )}
    </div>
  );
};

export default OperatorDashboard;
