import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./index.css";
import { useLoginMutation } from "../../RtkQuery/auth/auth";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons

const SignIn = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const navigate = useNavigate();

  const [login] = useLoginMutation(); // Using the signIn mutation hook

  // Update form values
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Validate form
  const validateForm = () => {
    const newErrors = {};
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email address";
    }

    if (!formData.password) {
      newErrors.password = "Password is required";
    } else if (formData.password.length < 8) {
      newErrors.password = "Password must be at least 8 characters long";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsSubmitting(true);
    try {
      const { data, error } = await login(formData); // Call RTK Query mutation

      if (error) {
        setErrors({ api: error.data.message || "Something went wrong" });
      } else {
        console.log(data); // Handle successful response
        localStorage.setItem("token", data.token);
        localStorage.setItem("role", data.user.role);
        localStorage.setItem("user", JSON.stringify(data.user));
        if (data.user.role === "operator") {
          console.log("operator", data.user);
          navigate("/service_request_form")
          localStorage.setItem("approvedStatus", data.user.approvedStatus);
        } else {
          localStorage.setItem("isApprovedByAdminOrIntake", true);
          localStorage.setItem("formStatus",data.user.formStatus)
          navigate(`/${data.user.role}_dashboard`);
        }
      }
    } catch (error) {
      setErrors({ api: "Network error. Please try again later." });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="auth-container">
      <h1 className="auth-title">Sign In</h1>
      <p className="auth-subtitle">Just some details to get you in!</p>
      <form className="auth-form" onSubmit={handleSubmit}>
        {errors.api && <p className="error">{errors.api}</p>}

        <input
          type="email"
          name="email"
          placeholder="Email Address"
          className="auth-input"
          value={formData.email}
          onChange={handleChange}
        />
        {errors.email && <p className="error">{errors.email}</p>}

        <div className="password-container" style={{ position: "relative" }}>
          <input
            style={{ width: "93%" }}
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder="Password"
            className="auth-input"
            value={formData.password}
            onChange={handleChange}
          />
          <span
            style={{ position: "absolute", bottom: "20%", right: "3%" }}
            onClick={() => setShowPassword(!showPassword)}
            className="password-toggle"
          >
            {showPassword ? (
              <FaEyeSlash style={{ color: "gray" }} />
            ) : (
              <FaEye style={{ color: "gray" }} />
            )}
          </span>
        </div>
        {errors.password && <p className="error">{errors.password}</p>}

        <button type="submit" className="auth-button" disabled={isSubmitting}>
          {isSubmitting ? "Signing In..." : "Sign In"}
        </button>
      </form>
      <p className="auth-footer">
        Don’t have an account? <Link to="/signup">Sign Up</Link>
      </p>
      <p className="auth-footer">
        <Link to="/forgot-password">Forgot Password?</Link>
      </p>
    </div>
  );
};

export default SignIn;
