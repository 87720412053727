import React, { useState } from "react";
import { Modal, Button } from "antd";
import { ExclamationCircleOutlined, DeleteOutlined } from "@ant-design/icons";

const ConfirmationModal = ({
  type = "delete",
  message = "Are you sure?",
  onConfirm,
}) => {
  //Modal operations
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => setIsModalVisible(true);
  const handleCancel = () => setIsModalVisible(false);
  const handleConfirm = () => {
    onConfirm();
    setIsModalVisible(false);
  };

  // Determine styles and icons based on the type of modal
  const isDelete = type === "delete";
  const icon = isDelete ? <DeleteOutlined /> : <ExclamationCircleOutlined />;
  const circleStyle = {
    backgroundColor: isDelete ? "#d32f2f" : "orange",
    color: "white",
    width: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    margin: "0 auto 16px",
  };

  return (
    <div>
      {isDelete ? (
        <Button
          shape="circle"
          color="danger"
          variant="solid"
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="size-6"
              height={"18px"}
              width={"18px"}
            >
              <path
                fill-rule="evenodd"
                d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                clip-rule="evenodd"
              />
            </svg>
          }
          // icon={<MdDelete />}
          onClick={showModal}
        />
      ) : (
        <Button onClick={showModal}>Cancel</Button>
      )}
      <Modal
        open={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        centered
        bodyStyle={{ textAlign: "center", padding: "24px" }}
      >
        {/* Circular Icon */}
        <div style={circleStyle}>{icon}</div>

        {/* Message */}
        <p
          style={{ fontSize: "16px", fontWeight: "500", marginBottom: "24px" }}
        >
          {message}
        </p>

        {/* Action Buttons */}
        <div style={{ display: "flex", justifyContent: "center", gap: "16px" }}>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            type={isDelete ? "primary" : "default"}
            danger={isDelete}
            onClick={handleConfirm}
          >
            {isDelete ? "Delete" : "Confirm"}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;
