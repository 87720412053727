import React, { useState } from "react";
import { useResetPasswordMutation } from "../../RtkQuery/auth/auth";
import { useSearchParams, useNavigate } from "react-router-dom";
import { message } from "antd";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons
import "./index.css";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isResetSuccessful, setIsResetSuccessful] = useState(false);

  const [resetPassword] = useResetPasswordMutation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get("token");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!password || password.length < 8) {
      message.error("Password must be at least 8 characters long");
      return;
    }

    if (password !== confirmPassword) {
      message.error("Passwords do not match");
      return;
    }

    setIsSubmitting(true);

    try {
      const { data, error } = await resetPassword({ token, password });

      if (error) {
        message.error(error.data?.message || "Something went wrong");
      } else {
        message.success("Password successfully reset");
        setIsResetSuccessful(true);
      }
    } catch {
      message.error("Network error. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleGoToSignIn = () => {
    navigate("/login");
  };

  return (
    <div className="auth-container">
      <h1 className="auth-title">Reset Password</h1>
      <p className="auth-subtitle">Enter your new password</p>
      <form className="auth-form" onSubmit={handleSubmit}>
        {/* Password Field */}
        <div className="password-container" style={{ position: "relative" }}>
          <input
            style={{ width: "93%" }}
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder="New Password"
            className="auth-input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <span
            style={{ position: "absolute", bottom: "20%", right: "3%" }}
            onClick={() => setShowPassword(!showPassword)}
            className="password-toggle"
          >
            {showPassword ? (
              <FaEyeSlash style={{ color: "gray", cursor: "pointer" }} />
            ) : (
              <FaEye style={{ color: "gray", cursor: "pointer" }} />
            )}
          </span>
        </div>

        {/* Confirm Password Field */}
        <div className="password-container" style={{ position: "relative" }}>
          <input
            style={{ width: "93%" }}
            type={showConfirmPassword ? "text" : "password"}
            name="confirmPassword"
            placeholder="Confirm Password"
            className="auth-input"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <span
            style={{ position: "absolute", bottom: "20%", right: "3%" }}
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            className="password-toggle"
          >
            {showConfirmPassword ? (
              <FaEyeSlash style={{ color: "gray", cursor: "pointer" }} />
            ) : (
              <FaEye style={{ color: "gray", cursor: "pointer" }} />
            )}
          </span>
        </div>

        {isResetSuccessful ? (
          <button
            type="button"
            className="auth-button"
            onClick={handleGoToSignIn}
          >
            Go to Sign In
          </button>
        ) : (
          <button type="submit" className="auth-button" disabled={isSubmitting}>
            {isSubmitting ? "Resetting..." : "Reset Password"}
          </button>
        )}
      </form>
    </div>
  );
};

export default ResetPassword;
