import React from "react";
import PropTypes from "prop-types";
import { Typography, Space, Image } from "antd";

const { Text } = Typography;

const ReviewMessage = ({ imageSrc, message }) => {
  return (
    <Space
      direction="vertical"
      align="center"
      style={{
        width: "100%",
        padding: "16px",
        textAlign: "center",
      }}
    >
      <Image
        width={450}
        // height={150}
        src={imageSrc}
        alt="Review"
        preview={false} // Disable image preview
        style={{
          objectFit: "contain",
        }}
      />
      <Text style={{ fontSize: "16px", color: "rgba(0, 0, 0, 0.65)" }}>
        {message}
      </Text>
    </Space>
  );
};

ReviewMessage.propTypes = {
  imageSrc: PropTypes.string.isRequired, // Path to the PNG image
  message: PropTypes.string, // Text message
};

ReviewMessage.defaultProps = {
  message: "Your request is under review, you will be notified shortly",
};

export default ReviewMessage;
