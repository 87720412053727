import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Button, Typography, Card, Divider } from "antd";
import "./index.css";
import { useGetOperatorsQuery } from "../../../RtkQuery/ServiceRequest";
import SearchBar from "../../../Components/UIKit/SearchBar";

const { Title } = Typography;

const OperatorListPage = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  
  // Fetch data with pagination
  const { data, error, isLoading, refetch } = useGetOperatorsQuery(
    {
      page: 1,
      limit: 10,
      role: "operator",
      search: searchTerm,
    },
    { refetchOnMountOrArgChange: true }
  );
  const formStatus = localStorage.getItem("formStatus");
  useEffect(() => {
    if (formStatus !== "approved") {
      navigate('/concierge_dashboard')
    }
  }, [formStatus]);


  // Table columns
  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Branch",
      dataIndex: "branch",
      key: "branch",
    },
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button
          type="primary"
          onClick={() => navigate(`/operator_details/${record._id}`)}
        >
          View Details
        </Button>
      ),
    },
  ];

  
  return (
    <div className="operator-list-container">
      <div className="operator-list-card">
        <Title level={2}>Operator List</Title>
        <Divider />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            marginBottom: "24px",
          }}
        >
          <div>
            <h3>All Operators</h3>
          </div>
          <div>
            <SearchBar
              placeholder="Search operators..."
              onSearch={(term) => setSearchTerm(term)}
            />
          </div>
        </div>
        <Table
          style={{ border: "2px solid #f0f0f0", borderRadius: "10px" }}
          columns={columns}
          dataSource={data}
          pagination={{ pageSize: 5 }}
          scroll={{ x: 800 }}
        />
      </div>
    </div>
  );
};

export default OperatorListPage;
