import React from 'react'
import ChatsPage from '../../../Components/ChatPage'

export default function OperatorChats () {
  const chatData = [
    { name: 'John Doe', lastMessage: 'Hello! How are you?' },
    { name: 'Jane Smith', lastMessage: 'Got your email. Thanks!' },
    { name: 'David Johnson', lastMessage: "Let's catch up later." }
  ]
  return (
    <div>
      <h1>My Chats</h1>
      <ChatsPage chatList={chatData} />
    </div>
  )
}
