import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Card,
  Typography,
  Spin,
  Alert,
} from "antd";
import { EditOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";
import "./index.css";
import {
  useGetUserByIdQuery,
  useUpdateUserMutation,
} from "../../../RtkQuery/users/usersApi";

const ICProfilePage = () => {
  const [form] = Form.useForm();
  const [editable, setEditable] = useState(false);
  const user = JSON.parse(localStorage.getItem("user")) || {};

  const { data, isLoading, refetch } = useGetUserByIdQuery(user._id);
  const [updateUser, { isLoading: updating, error: updateError }] =
    useUpdateUserMutation();
  const [error, setError] = useState("");

  const initialData = data && {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    password: "",
    currentPassword: "",
    contactNumber: data.phoneNumber,
    city: data.city,
    branch: data.branch,
    state: data.state,
    bio:data.bio,
    levelOfOperator: data.levelOfOperator,
  };

  const [userData, setUserData] = useState(initialData);

  const handleEditToggle = () => setEditable(!editable);

  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        const { password, currentPassword, ...rest } = values;
        const payload = password ? { ...rest, password, currentPassword } : rest;

        setUserData(payload);
        updateUser({ id: user._id, ...payload })
          .unwrap()
          .catch((err) => {
            setError(
              err?.data?.message || "An error occurred during the update."
            );
          });
        setEditable(false);
      })
      .catch((info) => {
        console.error("Validation Failed:", info);
      });
    refetch();
  };

  const handleCancel = () => {
    form.setFieldsValue(userData);
    setEditable(false);
  };

  useEffect(() => {
    if (data) {
      const formData = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        password: "",
        currentPassword: "",
        contactNumber: data.phoneNumber,
        city: data.city,
        branch: data.branch,
        state: data.state,
        levelOfOperator: data.levelOfOperator,
      };
      setUserData(formData);
      form.setFieldsValue(formData);
    }
  }, [data, form]);

  return (
    <>
      <Card
        className="profile-card"
        title={
          <Typography.Title level={2}>Profile Information</Typography.Title>
        }
        extra={
          <Button
            type="primary"
            icon={editable ? <CloseOutlined /> : <EditOutlined />}
            onClick={handleEditToggle}
          >
            {editable ? "Cancel" : "Edit"}
          </Button>
        }
      >
        {isLoading ? (
          <Spin
            tip="Loading..."
            style={{ display: "block", marginTop: "16px" }}
          />
        ) : (
          <Form form={form} layout="vertical" initialValues={userData}>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={[
                    { required: true, message: "First Name is required" },
                  ]}
                >
                  <Input disabled={!editable} placeholder="Enter first name" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={[{ required: true, message: "Last Name is required" }]}
                >
                  <Input disabled={!editable} placeholder="Enter last name" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: "Email is required" },
                    { type: "email", message: "Enter a valid email" },
                  ]}
                >
                  <Input disabled placeholder="Enter email" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="City"
                  name="city"
                  rules={[{ required: editable, message: "City is required" }]}
                >
                  <Input disabled={!editable} placeholder="Enter City Name" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Contact Number"
                  name="contactNumber"
                  rules={[
                    {
                      pattern: /^[0-9]+$/,
                      message: "Enter a valid contact number",
                    },
                  ]}
                >
                  <Input
                    disabled={!editable}
                    placeholder="Enter contact number"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item label="State" name="state">
                  <Input disabled={!editable} placeholder="Enter state" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Branch"
                  name="branch"
                  rules={[{ required: true, message: "Branch is required" }]}
                >
                  <Input disabled={!editable} placeholder="Enter Branch" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Level"
                  name="levelOfOperator"
                  rules={[{ required: true, message: "Level is required" }]}
                >
                  <Input
                    disabled={!editable}
                    placeholder="Enter Operator Level"
                  />
                </Form.Item>
              </Col>
              {/* Additional Field: About/Bio */}
              <Col xs={24}>
                <Form.Item
                  label="About/Bio"
                  name="bio"
                  rules={[
                    {
                      max: 500,
                      message: "About/Bio cannot exceed 500 characters",
                    },
                  ]}
                >
                  <Input.TextArea
                    disabled={!editable}
                    placeholder="Tell us about yourself"
                    rows={4}
                  />
                </Form.Item>
              </Col>
              {editable && (
                <>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="New Password"
                      name="password"
                      rules={[
                        {
                          min: 6,
                          message: "Password must be at least 6 characters",
                        },
                      ]}
                    >
                      <Input.Password placeholder="Enter new password" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Old Password"
                      name="currentPassword"
                      dependencies={["password"]}
                      rules={[
                        ({ getFieldValue }) => ({
                          required: !!getFieldValue("password"),
                          message:
                            "Old Password is required when changing password",
                        }),
                      ]}
                    >
                      <Input.Password placeholder="Enter old password" />
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
            {editable && (
              <div className="profile-actions">
                <Button
                  type="primary"
                  icon={<SaveOutlined />}
                  onClick={handleSave}
                  loading={updating}
                >
                  Save
                </Button>
                <Button onClick={handleCancel}>Cancel</Button>
              </div>
            )}
            {error && (
              <Alert
                message="Update Error"
                description={error}
                type="error"
                showIcon
                style={{ marginTop: "16px" }}
              />
            )}
          </Form>
        )}
      </Card>
    </>
  );
};

export default ICProfilePage;
