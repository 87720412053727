import React, { useEffect, useState } from "react";
import ReviewMessage from "../../../Components/WaitingScreens/formUnderReview";
import img from '../../../assets/Images/underReview2.png'
import SPFormModal from "../../../Components/Modals/ApplicationForms/serviceProvider";
import { useGetRequestsQuery } from "../../../RtkQuery/ServiceRequest";

const SPDashboard = () => {
    const [refetch, setRefetch] = useState(false);
  const [formStatus, setFormStatus] = useState(
    localStorage.getItem("formStatus")
  );

  useEffect(() => {
    setFormStatus(localStorage.getItem("formStatus"));
  }, [formStatus, refetch]);

  return (
    <div style={{ padding: "16px" }}>
      {formStatus === "formSubmitted" ? (
        <div>
          <ReviewMessage
            message={
              "Please wait, your request is under review and you will be notified shortly"
            }
            imageSrc={img}
          />
        </div>
      ) : formStatus === "rejected" ? (
        <div style={{fontSize:'22px', color:'red',margin:'5rem 10rem'}}>
          Your signup request has been reviewed and unfortunately, it has been
          rejected. If you have any questions or need further assistance, please
          contact our support team.
        </div>
      ) : formStatus === "approved" ? (
        <div></div>
      ) : (
        <div><SPFormModal setState={setRefetch}/></div>
      )}
    </div>
  );
};

export default SPDashboard;
