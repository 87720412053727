import React from "react";
import { Card, Button, Divider, List } from "antd";

const AssignCardList = ({
  title,
  items,
  onAssign,
  isConciergeList = false,
  loading,
}) => {
  return (
    <Card style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3 style={{ margin: 0 }}>{title}</h3>
      </div>
      <Divider />
      <div
        style={{
          maxHeight: "150px",
          overflowY: "auto",
        }}
      >
        <List
          dataSource={items}
          renderItem={(item) => {
            return (
              <List.Item
                actions={[
                  isConciergeList ? (
                    <Button
                      type="primary"
                      disabled={loading}
                      onClick={() => onAssign(item._id, null)}
                      key="assign"
                    >
                      Assign
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      disabled={loading}
                      onClick={() => onAssign(null, item._id)}
                      key="assign"
                    >
                      Assign
                    </Button>
                  ),
                ]}
              >
                {item.firstName + "" + item.lastName}
              </List.Item>
            );
          }}
        />
      </div>
    </Card>
  );
};

export default AssignCardList;
