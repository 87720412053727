import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'

const PublicRoutes = () => {
  const role = localStorage.getItem('role')

  return role ? <Navigate to={`/${role}_dashboard`} /> : <Outlet />
}

export default PublicRoutes
