import React from "react";
import ChatsPage from "../../../Components/ChatPage";
import { useParams } from "react-router-dom";

export default function IntakeChats() {
  const chatData = [
    {
      name: "John Doe",
      lastMessage: "Hello! How are you?",
      id: "674e3a8da77fc28936",
    },
    { name: "Jane Smith", lastMessage: "Got your email. Thanks!",id:'674e3a8da77ffddc80c28936' },
    { name: "David Johnson", lastMessage: "Let's catch up later." },
  ];
  return (
    <div>
      <h1>Chats</h1>
      <ChatsPage chatList={chatData} />
    </div>
  );
}
