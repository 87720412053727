import React, { useState, useEffect } from "react";
import { Input } from "antd";

const SearchBar = ({ placeholder = "Search...", onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState("");

  // Debounce logic
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedTerm(searchTerm);
    }, 500); // Adjust debounce delay as needed (500ms here)
    return () => clearTimeout(timer); // Cleanup timeout on component unmount or input change
  }, [searchTerm]);

  // Trigger the search function when debouncedTerm changes
  useEffect(() => {
    if (onSearch) {
      onSearch(debouncedTerm);
    }
  }, [debouncedTerm, onSearch]);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <Input
      placeholder={placeholder}
      value={searchTerm}
      onChange={handleInputChange}
      allowClear
    />
  );
};

export default SearchBar;
