import React, { useEffect, useState } from "react";
import { Typography, Divider, Button, Spin } from "antd";
import DynamicTable from "../../../Components/UIKit/Table";
import { useNavigate } from "react-router-dom";
import { useGetAllUsersQuery } from "../../../RtkQuery/users/usersApi";
import dayjs from "dayjs";
import SearchBar from "../../../Components/UIKit/SearchBar";
import PAFirstLoginModal from "../../../Components/Modals/ApplicationForms/peerAmbassador";
import ReviewMessage from "../../../Components/WaitingScreens/formUnderReview";
import img from "../../../assets/Images/underReview2.png";

const { Title } = Typography;

const PADashboard = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [refetch, setRefetch] = useState(false)
  const [formStatus, setFormStatus] = useState(
    localStorage.getItem("formStatus")
  );

  const { data, error, isLoading } = useGetAllUsersQuery(
    {
      page: 1,
      limit: 10,
      role: "operator",
      search: searchTerm,
    },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    setFormStatus(localStorage.getItem("formStatus"));
  }, [formStatus, refetch]);

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button
          type="primary"
          onClick={() =>
            navigate(`/peerAmbassador_operatorDetails/${record._id}`)
          }
        >
          View Details
        </Button>
      ),
    },
  ];

  // useEffect(() => {

  // }, [formStatus]);

  return (
    <div style={{ padding: "16px" }}>
      {formStatus === "formSubmitted" ? (
        <div>
          <ReviewMessage
            message={
              "Please wait, your request is under review and you will be notified shortly"
            }
            imageSrc={img}
          />
        </div>
      ) : formStatus === "rejected" ? (
        <div style={{fontSize:'22px', color:'red',margin:'5rem 10rem'}}>
          Your signup request has been reviewed and unfortunately, it has been
          rejected. If you have any questions or need further assistance, please
          contact our support team.
        </div>
      ) : formStatus === "approved" ? (
        <div>
          <Title level={2}>Dashboard</Title>
          <Divider />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              marginBottom: "24px",
            }}
          >
            <div>
              <h3>Operators</h3>
            </div>
            <div>
              <SearchBar
                placeholder="Search operators..."
                onSearch={(term) => setSearchTerm(term)}
              />
            </div>
          </div>

          {isLoading ? (
            <Spin
              tip="Loading..."
              style={{ display: "block", marginTop: "16px" }}
            />
          ) : error ? (
            <p style={{ color: "red" }}>
              Failed to load data. Please try again later.
            </p>
          ) : (
            <DynamicTable columns={columns} data={data} />
            // <></>
          )}
        </div>
      ) : (
        <div><PAFirstLoginModal setState={setRefetch}/></div>
      )}
    </div>
  );
};

export default PADashboard;
