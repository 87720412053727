import React, { useState } from "react";
import { Card, List, Button, Typography, Row, Col } from "antd";
import { MessageOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const { Title, Text } = Typography;

const  DualListCard = ({
  titleOne,
  listOne,
  titleTwo,
  listTwo,
  chatUrl,
  currentUserId = null,
  showRemoveConcierge = true,
  showRemoveAmbassador = true,
  onUnAssign,
}) => {
  const navigate = useNavigate();



  return (
    <Card >
      <Row gutter={16}>
        {/* First List */}
        <Col span={12}>
          <Title level={5} style={{ margin: "0.5rem 0" }}>{titleOne}</Title>
          <List
            dataSource={listOne}
            renderItem={(item) => {
              return (
                <List.Item
                  actions={[
                    <Button
                      type="primary"
                      icon={<MessageOutlined />}
                      onClick={() => navigate(`${chatUrl}/${item._id}`)}
                      disabled={currentUserId && item._id === currentUserId}
                    >
                      Chat
                    </Button>,
                    showRemoveConcierge ? (
                      <Button
                        type="default"
                        onClick={() => onUnAssign(item._id, null)}
                        disabled={currentUserId && item._id === currentUserId}
                      >
                        Remove
                      </Button>
                    ) : (
                      <></>
                    ),
                  ]}
                >
                  <Text>
                    {item.firstName} {item.lastName}
                  </Text>
                </List.Item>
              );
            }}
          />
        </Col>

        {/* Second List */}
        <Col span={12}>
          <Title level={5} style={{ margin: "0.5rem 0" }}>{titleTwo}</Title>
          <List
            dataSource={listTwo}
            renderItem={(item) => {
              return (
                <List.Item
                  actions={[
                    <Button
                      type="primary"
                      icon={<MessageOutlined />}
                      onClick={() => navigate(`${chatUrl}/${item._id}`)}
                      disabled={currentUserId && item._id === currentUserId}
                    >
                      Chat
                    </Button>,
                   showRemoveAmbassador ? (
                      <Button
                        type="default"
                        onClick={() =>
                          onUnAssign(null,item._id)
                        }
                        disabled={currentUserId && item._id === currentUserId}
                      >
                        Remove
                      </Button>
                    ) : (
                      <></>
                    ),
                  ]}
                >
                  <Text>
                    {item.firstName} {item.lastName}
                  </Text>
                </List.Item>
              );
            }}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default DualListCard;
