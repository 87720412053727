import React, { useState } from "react";
import { Form, Input, Button, Select, Typography, message } from "antd";
import { useCreateUserMutation } from "../../../RtkQuery/users/usersApi";

const { Title } = Typography;
const { Option } = Select;

const AdminCreateUserPage = () => {
  const [form] = Form.useForm();
  const [createUser, { isLoading }] = useCreateUserMutation();
  const roles = [
    { label: "Admin", value: "admin" },
    { label: "Concierge", value: "concierge" },
    { label: "Intake", value: "intakeCare" },
    { label: "Peer Ambassador", value: "peerAmbassador" },
    { label: "Operator", value: "operator" },
  ];

  const handleFinish = async (values) => {
    try {
      await createUser(values).unwrap(); // Unwrap to handle errors correctly
      message.success("User created successfully!");
      form.resetFields();
    } catch (error) {
      message.error(error?.data?.message || "Failed to create user.");
    }
  };

  return (
    <div
      style={{
        maxWidth: "600px",
        margin: "0 auto",
        padding: "20px",
        background: "#fff",
        borderRadius: "8px",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Title level={2} style={{ textAlign: "center" }}>
        Create New User
      </Title>

      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Form.Item
          label="First Name"
          name="firstName"
          rules={[{ required: true, message: "Please enter the first name" }]}
        >
          <Input placeholder="Enter first name" />
        </Form.Item>

        <Form.Item
          label="Last Name"
          name="lastName"
          rules={[{ required: true, message: "Please enter the last name" }]}
        >
          <Input placeholder="Enter last name" />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Please enter the email" },
            { type: "email", message: "Please enter a valid email" },
          ]}
        >
          <Input placeholder="Enter email address" />
        </Form.Item>

        <Form.Item
          label="Phone Number"
          name="phoneNumber"
          rules={[{ required: true, message: "Please enter the phone number" }]}
        >
          <Input placeholder="Enter phone number" />
        </Form.Item>

        <Form.Item
          label="Role"
          name="role"
          rules={[{ required: true, message: "Please select a role" }]}
        >
          <Select placeholder="Select a role">
            {roles.map((role) => (
              <Option key={role.value} value={role.value}>
                {role.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            style={{ width: "100%" }}
          >
            Create User
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AdminCreateUserPage;
