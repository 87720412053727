import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Select, Button, Typography } from "antd";
import { useSubmitApplicationFormMutation } from "../../../RtkQuery/ServiceRequest";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../ConfirmationModals";

const { TextArea } = Input;
const { Title } = Typography;

const SPFormModal =  ({setState}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()

  const formStatus = localStorage.getItem("formStatus");

  const [submitApplicationForm] = useSubmitApplicationFormMutation();

  // Form instance
  const [form] = Form.useForm();

  useEffect(() => {
    if (formStatus === "pending") {
      setIsModalOpen(true);
    }
  }, [formStatus]);

  const handleFormSubmit = async (formData) => {
    setLoading(true);
    try {
      await submitApplicationForm(formData).unwrap(); // Call the RTK mutation and unwrap the response
      setLoading(false);
      localStorage.setItem("formStatus", "formSubmitted");
      setState(true)

      setIsModalOpen(false);
    } catch (error) {
      console.error("Submission error:", error);
      setLoading(false);
    }

    console.log(formData);
  };

  const handleFormCancel =  () => {
    localStorage.clear() 
    navigate('/')
  }

  return (
    <Modal
      title={
        <div style={{display:'flex', justifyContent:'space-between', alignContent:'center'}}>
          <div>
          <Title level={3}>Application Form</Title>
          <p style={{ color: "gray", fontWeight: "lighter", fontSize: "14px" }}>
            Please fill this form to request account approval.
          </p>
          </div>
          <ConfirmationModal type="warning" message="You will be logged out, continue?" onConfirm={handleFormCancel}/>
        </div>
      }
      open={isModalOpen}
      footer={null}
      closable={false}
      centered
      width={700}
      bodyStyle={{
        maxHeight: "80vh", // Fixed modal height
        overflowY: "auto", // Scrollable content
        padding: "20px",
      }}
    >
      <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
        {/* Organization/Company Information */}
        <Title level={4}>Organization/Company Information</Title>
        <Form.Item
          label="Organization/Company Name"
          name="organizationName"
          rules={[{ required: true, message: "Please enter the organization/company name." }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Address"
          name="organizationAddress"
          rules={[{ required: true, message: "Please enter the address." }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Phone"
          name="organizationPhone"
          rules={[{ required: true, message: "Please enter the phone number." }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Website URL"
          name="organizationWebsite"
          rules={[{ required: true, message: "Please enter the website URL." }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Point of Contact Name"
          name="pointOfContactName"
          rules={[{ required: true, message: "Please enter the point of contact name." }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Point of Contact Email"
          name="pointOfContactEmail"
          rules={[{ required: true, message: "Please enter the point of contact email." }]}
        >
          <Input type="email" />
        </Form.Item>
        <Form.Item
          label="For-Profit or Non-Profit"
          name="forProfitOrNonProfit"
          rules={[{ required: true, message: "Please select an option." }]}
        >
          <Select>
            <Select.Option value="for-profit">For-Profit</Select.Option>
            <Select.Option value="non-profit">Non-Profit</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="If Non-Profit, please provide 501(c)3 Number"
          name="nonProfit501c3Number"
        >
          <Input />
        </Form.Item>

        {/* Services */}
        <Title level={4}>Services</Title>
        <Form.Item
          label="Services Provided"
          name="servicesProvided"
          rules={[{ required: true, message: "Please provide the services offered." }]}
        >
          <TextArea rows={2} />
        </Form.Item>
        <Form.Item
          label="Veteran Specific Services Provided"
          name="veteranSpecificServicesProvided"
        >
          <TextArea rows={2} />
        </Form.Item>

        {/* Partnership with My360Tribe */}
        <Title level={4}>Partnership with My360Tribe</Title>
        <Form.Item
          label="Please tell us a little about your company/organization and why you want to partner with My360Tribe"
          name="partnershipDescription"
          rules={[{ required: true, message: "Please provide details." }]}
        >
          <TextArea rows={3} />
        </Form.Item>
        <Form.Item
          label="How did you hear about us?"
          name="partnershipDiscovery"
          rules={[{ required: true, message: "Please let us know how you heard about us." }]}
        >
          <TextArea rows={2} />
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          block
          style={{ margin: "1rem 0" }}
        >
          Submit
        </Button>
      </Form>
    </Modal>
  );
};

export default SPFormModal;
