import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Divider,
  Typography,
  Spin,
  Alert,
  Row,
  Col,
  Table,
  Tag,
} from "antd";
import { EyeOutlined } from "@ant-design/icons";
import DynamicTable from "../../../Components/UIKit/Table";
import { useGetRequestsQuery } from "../../../RtkQuery/ServiceRequest";
import dayjs from "dayjs";
import SearchBar from "../../../Components/UIKit/SearchBar";

const IntakeCareDashboard = () => {
  const navigate = useNavigate();
  const { Title, Paragraph } = Typography;
  const [searchTerm, setSearchTerm] = useState("");
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  // Fetch requests using RTK Query
  const { data, error, isLoading } = useGetRequestsQuery(
    {
      page: pagination.current,
      limit: pagination.pageSize,
      search: searchTerm,
    },
    { refetchOnMountOrArgChange: true }
  );

  // Table columns
  const columns = [
    {
      title: "Support Type",
      dataIndex: "supportType",
      key: "supportType",
    },
    {
      title: "Health Support Type",
      dataIndex: "mentalHealthSupportType",
      key: "mentalHealthSupportType",
    },
    {
      title: "Requestee Name",
      key: "requesteeName",
      render: (_, record) =>
        `${record.operator?.firstName || ""} ${
          record.operator?.lastName || ""
        }`.trim(),
    },
    {
      title: "Request Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => dayjs(createdAt).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "Status",
      dataIndex: "approvedStatus",
      key: "approvedStatus",
      render: (_, record) => (
        <Tag
          style={{
            color:
              record.approvedStatus === "pending" || record.status === "pending"
                ? "orange"
                : record.approvedStatus === "approved" ||
                  record.status === "completed"
                ? "green"
                : "red",
          }}
        >
          {record.approvedStatus === "pending"
            ? "Pending"
            : record.approvedStatus === "approved" &&
              record.status === "pending"
            ? "In progress"
            : record.status === "completed"
            ? "Completed"
            : "Rejected"}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button
          type="primary"
          icon={<EyeOutlined />}
          onClick={() => navigate(`/intakecare/request/${record._id}`)}
        >
          View Details
        </Button>
      ),
    },
  ];

  // Filter data based on search term
  const filteredData = data?.data?.filter((item) =>
    JSON.stringify(item).toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleTableChange = (pagination) => {
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  return (
    <div style={{ padding: "16px" }}>
      <Title level={2}>Dashboard</Title>
      <Divider />

      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          marginBottom: "24px",
        }}
      >
        <div>
          <h3>Requests</h3>
        </div>
        <div>
          <SearchBar
            placeholder="Search requests..."
            onSearch={(term) => setSearchTerm(term)}
          />
        </div>
      </div>

      {isLoading && (
        <div style={{ textAlign: "center", padding: "24px 0" }}>
          <Spin size="large" tip="Loading requests..." />
        </div>
      )}

      {error && (
        <Alert
          message="Error"
          description="An error occurred while fetching requests. Please try again later."
          type="error"
          showIcon
          style={{ marginBottom: "16px" }}
        />
      )}

      {!isLoading && !error && filteredData?.length === 0 && (
        <Paragraph style={{ textAlign: "center", color: "gray" }}>
          No requests found.
        </Paragraph>
      )}

      {!isLoading && !error && filteredData?.length > 0 && (
        <Table
          style={{ border: "2px solid #f0f0f0", borderRadius: "10px" }}
          columns={columns}
          dataSource={filteredData} // Use state-based data
          rowKey="_id" // Use a unique key for rows
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: data?.total || 0,
          }}
          onChange={handleTableChange}
          loading={isLoading}
        />
      )}
    </div>
  );
};

export default IntakeCareDashboard;
