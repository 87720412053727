import { configureStore } from '@reduxjs/toolkit'
import { authApi } from './auth/auth'
import { serviceRequestApi } from './ServiceRequest'
import { chatApi } from './chat/chatApi'
import { usersApi } from './users/usersApi'
import { notesApi } from './notes/notesApi'

const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [serviceRequestApi.reducerPath]: serviceRequestApi.reducer,
    [chatApi.reducerPath]: chatApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [notesApi.reducerPath]: notesApi.reducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      serviceRequestApi.middleware,
      chatApi.middleware,
      usersApi.middleware,
      notesApi.middleware
    )
})

export default store
