import React from 'react'
import './index.css'
import img from '../../assets/Images/approved.png'
import { Image, Typography, Button } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

const { Title, Text } = Typography

const SignupSuccess = () => {
  const navigate = useNavigate()
  return (
    <div
      className='signup-success-container'
      style={{ backgroundColor: 'white' }}
    >
      <Image
        src={img}
        preview={false}
        alt='Success'
        className='signup-success-image'
      />
      <Title level={2} className='signup-success-title'>
        You have successfuly signed up!
      </Title>
      <Text className='signup-success-text'>Check your email for Login.</Text>
      {/* <Button
        type='link'
        icon={<ArrowLeftOutlined />}
        onClick={() => navigate('/login')}
        className='signup-success-back-button'
      >
        Login
      </Button> */}
    </div>
  )
}

export default SignupSuccess
