import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Table, Button, Row, Col, Typography, Spin, message } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { LeftOutlined, MessageOutlined } from "@ant-design/icons";
import "./index.css";
import {
  useGetOperatorRequestHistoryQuery,
  useGetOperatorsQuery,
} from "../../../RtkQuery/ServiceRequest";
import {
  useAssignStaffToOperatorMutation,
  useUnAssignStaffToOperatorMutation,
} from "../../../RtkQuery/users/usersApi";
import { useGetUserByIdQuery } from "../../../RtkQuery/users/usersApi";
import dayjs from "dayjs";
import DualListCard from "../../../Components/UIKit/DualListCard";
import AssignCardList from "../../../Components/UIKit/PersonnelList";

const OperatorDetails = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const [peerAmbassadorUsers, setPeerAmbassadorUsers] = useState([]);
  const { Title, Text } = Typography;
  const { id } = useParams();

  const { data, isLoading } = useGetOperatorRequestHistoryQuery(id, {
    refetchOnMountOrArgChange: true,
  });
  const [assignStaffToOperator, { isLoading: assigningStaff }] =
    useAssignStaffToOperatorMutation();

  const [unAssignStaffToOperator, { isLoading: unAssigningStaff }] =
    useUnAssignStaffToOperatorMutation();

  const {
    data: userData,
    isLoading: userLoading,
    refetch,
  } = useGetUserByIdQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  //get list of users to assign
  const {
    data: PAData,
    error: conError,
    isLoading: conLoading,
  } = useGetOperatorsQuery({
    page: 1,
    limit: 10,
    role: "peerAmbassador",
  });

  // Table columns for request history
  const columns = [
    {
      title: "Support Type",
      dataIndex: "supportType",
      key: "supportType",
    },
    {
      title: "Healty Support Type",
      dataIndex: "mentalHealthSupportType",
      key: "mentalHealthSupportType",
    },
    {
      title: "Request Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => dayjs(createdAt).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        const statusColors = {
          pending: "orange",
          approved: "green",
          rejected: "red",
        };
        return (
          <span style={{ color: statusColors[status] || "black" }}>
            {status.charAt(0).toUpperCase() + status.slice(1)}
          </span>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button
          type="primary"
          icon={<EyeOutlined />}
          onClick={() => navigate(`/concierge/request/${record._id}`)}
        >
          View Details
        </Button>
      ),
    },
  ];

    const handleAssignStaff = async (conId, paId) => {
      const payload = {
        conciergeIds: conId !== null ? [conId] : [],
        peerAmbassadorIds: paId !== null ? [paId] : [],
        intake: user._id,
      };
      try {
        const response = await assignStaffToOperator({ id, ...payload });
        if (response) {
          message.success("Staff successfully assigned!");
          refetch(); // Refresh the request details
        }
      } catch (error) {
        message.error("Failed to assign staff. Please try again.");
      }
    };
  
    const handleUnAssignStaff = async (conId, paId) => {
      const payload = {
        conciergeIds: conId !== null ? [conId] : [],
        peerAmbassadorIds: paId !== null ? [paId] : [],
        intake: user._id,
      };
      try {
        const response = await unAssignStaffToOperator({ id, ...payload });
        if (response) {
          message.success("Staff successfully unassigned!");
          refetch(); // Refresh the request details
        }
      } catch (error) {
        message.error("Failed to unassign staff. Please try again.");
      }
    };

  useEffect(() => {
    if (PAData) {
      const assignedPeerAmbassadorIds = (
        userData?.assignedPeerAmbassador || []
      ).map((user) => user._id);
      const peerAmbassadorFiltered = PAData.filter(
        (user) =>
          user.role === "peerAmbassador" &&
          !assignedPeerAmbassadorIds.includes(user._id)
      );
      setPeerAmbassadorUsers(peerAmbassadorFiltered);
    }
  }, [PAData, userData?.assignedPeerAmbassador]);

  return (
    <div className="con-operator-details-container">
      {/* Top Navigation */}
      <div className="header">
        <Button icon={<LeftOutlined />} onClick={() => navigate(-1)}>
          Back
        </Button>
      </div>
      {/* Operator Details Card */}
      {userLoading ? (
        <Spin
          tip="Loading..."
          style={{ display: "block", marginTop: "16px" }}
        />
      ) : (
        <Card
                  title={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Title level={4} style={{ margin: "0.5rem 0" }}>
                        User Details
                      </Title>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          type="primary"
                          icon={<MessageOutlined />}
                          style={{ marginRight: "1rem" }}
                          onClick={() => navigate(`/concierge_chats/${userData._id}`)}
                        >
                          Chat
                        </Button>
                        {userData?.role === "operator" && (
                          <Button
                            type="primary"
                            onClick={() => navigate(`/notes/${id}?type=operator`)}
                          >
                            View Notes
                          </Button>
                        )}
                      </div>
                    </div>
                  }
                  className="details-card"
                >
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={8}>
                      <Text strong>Name: </Text>
                      <Text>{userData?.firstName + " " + userData?.lastName}</Text>
                    </Col>
                    <Col xs={24} sm={8}>
                      <Text strong>Phone Number: </Text>
                      <Text>{userData.phoneNumber}</Text>
                    </Col>
                    <Col xs={24} sm={8}>
                      <Text strong>Email: </Text>
                      <Text>{userData.email}</Text>
                    </Col>
                    <Col xs={24} sm={8}>
                      <Text strong>Level: </Text>
                      <Text>{userData.levelOfOperator}</Text>
                    </Col>
        
                    <Col xs={24} sm={8}>
                      <Text strong>Role: </Text>
                      <Text>{userData.role}</Text>
                    </Col>
                    <Col xs={24} sm={8}>
                      <Text strong>Branch: </Text>
                      <Text>{userData?.branch}</Text>
                    </Col>
                    <Col xs={24} sm={8}>
                      <Text strong>State: </Text>
                      <Text>{userData.state}</Text>
                    </Col>
                    <Col xs={24} sm={8}>
                      <Text strong>City: </Text>
                      <Text>{userData.city}</Text>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "1rem" }}>
                    <Col span={24}>
                      <Text strong>About: </Text>
                      <p>{userData.bio}</p>
                    </Col>
                  </Row>
                </Card>
      )}

      <DualListCard
        listOne={userData?.assignedConcierge}
        listTwo={userData?.assignedPeerAmbassador}
        titleOne={"Assigned Concierge"}
        titleTwo={"Assigned Peer Ambassador"}
        chatUrl={"/concierge_chats"}
        onUnAssign={handleUnAssignStaff}
        showRemoveConcierge={false}
        currentUserId={user._id}
      />

      {/* Assign concierge and peerAmbassadors */}
      {userData && userData.approvedStatus === "approved" && (
        <div xs={24} sm={12} style={{ marginTop: "16px" }}>
          {conLoading ? (
            <Spin
              tip="Loading..."
              style={{ display: "block", marginTop: "16px" }}
            />
          ) : (
            <AssignCardList
              title="Assign Peer Ambassador"
              items={peerAmbassadorUsers}
              onAssign={handleAssignStaff}
                loading={assigningStaff}
            />
          )}
        </div>
      )}

      {/* Request History Table */}
      <Title level={4}>Request History</Title>
      {isLoading ? (
        <Spin
          tip="Loading..."
          style={{ display: "block", marginTop: "16px" }}
        />
      ) : (
        <Table
          style={{ border: "2px solid #f0f0f0", borderRadius: "10px" }}
          columns={columns}
          dataSource={data}
          rowKey="id"
          pagination={{ pageSize: 10 }}
        />
      )}
    </div>
  );
};

export default OperatorDetails;
