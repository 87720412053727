import React from "react";
import {
  Card,
  Button,
  Typography,
  Row,
  Col,
  Spin,
  Alert,
  message,
  Divider,
  Tag,
} from "antd";
import { LeftOutlined, MessageOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useGetSingleRequestQuery } from "../../../RtkQuery/ServiceRequest"; // Import the getSingleRequest API hook
import { useGetReqNoteByIdQuery } from "../../../RtkQuery/notes/notesApi";
import "./index.css";
import { useApproveRequestMutation, useCompleteRequestMutation } from "../../../RtkQuery/ServiceRequest";
import dayjs from "dayjs";
import SanitizedHTML from "../../../Components/SanitizeHtml";
import FileViewerModal from "../../../Components/Modals/FileViewer";
import { baseUrl } from "../../../Apis";


const { Title, Text } = Typography;

const SARequestDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Get request ID from the URL
  const { data, isLoading, refetch, error } = useGetSingleRequestQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  // Fetch request notes
  const { data: reqNote, isLoading: isReqFetching } = useGetReqNoteByIdQuery(
    id,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const startChat = (id) => {
    navigate(`/superAdmin_chats/${id}`);
  };

  const [approveRequest, { isLoading: approvalLoading, error: approvalError }] =
    useApproveRequestMutation();

    const [completeRequest, { isLoading: completing, error: completeError }] =
    useCompleteRequestMutation();

  const handleApprove = async () => {
    try {
      const response = await approveRequest({ id, approvedStatus: "approved" });
      // Handle successful approval
      if (response) {
        message.success("Request Approved!");
        // navigate("/superAdmin_requests");
        refetch()
      }
    } catch (err) {
      // Handle error
      message.error("Failed to approve the request.");
    }
  };
  const handleComplete = async () => {
    try {
      const response = await completeRequest({ id, status: "completed" });
      // Handle successful approval
      if (response) {
        message.success("Request Completed!");
        // navigate("/superAdmin_requests");
        refetch()
      }
    } catch (err) {
      // Handle error
      message.error("Failed to approve the request.");
    }
  };
  // const handleReject = async () => {
  //   try {
  //     const response = await approveRequest({ id, approvedStatus: "rejected" });
  //     // Handle successful approval
  //     console.log(response);
  //     if (response) {
  //       message.success("Request Rejected!");
  //       navigate("/admin_dashboard");
  //     }
  //   } catch (err) {
  //     // Handle error
  //     message.error("Failed to approve the request.");
  //   }
  // };

  if (isLoading) {
    return (
      <Spin tip="Loading..." style={{ display: "block", marginTop: "16px" }} />
    );
  }

  if (error) {
    return (
      <Alert
        message="Error"
        description="Failed to load request details"
        type="error"
      />
    );
  }

  return (
    <div className="details-container">
      {/* Back Button */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button
          className="back-button"
          icon={<LeftOutlined />}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
        {data?.approvedStatus === "pending" && (
          <div style={{ display: "flex", gap: "1rem" }}>
            <Button
              style={{ marginLeft: "auto" }}
              type="primary"
              onClick={handleApprove}
            >
              Approve Request
            </Button>
          </div>
        )}
        {(data?.status === "pending" && data?.approvedStatus === "approved") && (
          <div style={{ display: "flex", gap: "1rem" }}>
            <Button
              style={{ marginLeft: "auto" }}
              type="primary"
              onClick={handleComplete}
            >
              Complete Request
            </Button>
          </div>
        )}
      </div>

      {data && (
        <Card
          title={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Title level={4} style={{ margin: "0.5rem 0" }}>
                Operator Details
              </Title>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button
                  type="primary"
                  icon={<MessageOutlined />}
                  style={{ marginRight: "1rem" }}
                  onClick={() =>
                    navigate(`/superAdmin_chats/${data.operator._id}`)
                  }
                >
                  Chat
                </Button>
                {data.operator?.role === "operator" && (
                  <Button
                    type="primary"
                    onClick={() => navigate(`/notes/${id}?type=operator`)}
                  >
                    View Notes
                  </Button>
                )}
              </div>
            </div>
          }
          className="details-card"
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={8}>
              <Text strong>Name: </Text>
              <Text>
                {data?.operator?.firstName + " " + data?.operator?.lastName}
              </Text>
            </Col>
            <Col xs={24} sm={8}>
              <Text strong>Phone Number: </Text>
              <Text>{data.operator.phoneNumber}</Text>
            </Col>
            <Col xs={24} sm={8}>
              <Text strong>Email: </Text>
              <Text>{data.operator.email}</Text>
            </Col>
            <Col xs={24} sm={8}>
              <Text strong>Level: </Text>
              <Text>{data.operator.levelOfOperator}</Text>
            </Col>

            <Col xs={24} sm={8}>
              <Text strong>Role: </Text>
              <Text>{data.operator.role}</Text>
            </Col>
            <Col xs={24} sm={8}>
              <Text strong>Branch: </Text>
              <Text>{data.operator?.branch}</Text>
            </Col>
            <Col xs={24} sm={8}>
              <Text strong>State: </Text>
              <Text>{data.operator.state}</Text>
            </Col>
            <Col xs={24} sm={8}>
              <Text strong>City: </Text>
              <Text>{data.operator.city}</Text>
            </Col>
          </Row>
          <Row style={{ marginTop: "1rem" }}>
            <Col span={24}>
              <Text strong>About: </Text>
              <p>{data.operator.bio}</p>
            </Col>
          </Row>
        </Card>
      )}

      {data && (
        <Card
          className="details-card"
          title={
            <Title level={4} style={{ margin: "0.5rem 0" }}>
              Request Details
            </Title>
          }
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Text strong>ID: </Text>
              <Text>{data._id}</Text>
            </Col>
            <Col xs={24} sm={12}>
              <Text strong>Support Type: </Text>
              <Text>{data.supportType}</Text>
            </Col>
            <Col xs={24} sm={12}>
              <Text strong>Health Support Type: </Text>
              <Text>{data.mentalHealthSupportType}</Text>
            </Col>
            <Col xs={24} sm={12}>
              <Text strong>Request Time: </Text>
              <Text>
                {data.createdAt
                  ? dayjs(data.createdAt).format("YYYY-MM-DD HH:mm A")
                  : ""}
              </Text>
            </Col>
            <Col xs={24} sm={12}>
              <Text strong>Employment Status: </Text>
              <Text>
                {data.isCurrentlyEmployed === true ? "Employed" : "Unemployed"}
              </Text>
            </Col>
            <Col span={12}>
              <Text strong>Status:</Text>{" "}
              <Tag
                style={{
                  color:
                    data.approvedStatus === "pending" ||
                    data.status === "pending"
                      ? "orange"
                      : data.approvedStatus === "approved" ||
                        data.status === "completed"
                      ? "green"
                      : "red",
                }}
              >
                {data.approvedStatus === "pending"
                  ? "Pending"
                  : data.approvedStatus === "approved" &&
                    data.status === "pending"
                  ? "In progress"
                  : data.status === "completed"
                  ? "Completed"
                  : "Rejected"}
              </Tag>
            </Col>
            {data.fileUrl && (
              <Col xs={24} sm={12}>
                <Text strong>Uploaded File: </Text>
                <FileViewerModal fileUrl={baseUrl + "/" + data.fileUrl} />
              </Col>
            )}
          </Row>
          <Row style={{ marginTop: "1rem" }}>
            <Col span={24}>
              <Text strong>Comments: </Text>
              <Text>{data.comment}</Text>
            </Col>
          </Row>
        </Card>
      )}

      {reqNote && (
        <Card
          className="details-card"
          title={
            <Title level={4} style={{ margin: "0.5rem 0" }}>
              Notes
            </Title>
          }
        >
          <Title level={5}>{reqNote[0]?.title || "No Notes Yet"}</Title>
          <Typography.Paragraph>
            <SanitizedHTML
              html={reqNote[0]?.content || "No content available"}
            />
          </Typography.Paragraph>
          <Button
            type="primary"
            onClick={() => navigate(`/notes/${id}?type=request`)}
            style={{ float: "right" }}
          >
            Request Notes
          </Button>
        </Card>
      )}

      <div style={{ marginLeft: "auto", display: "flex", gap: "1rem" }}>
        {/* {data.approvedStatus === "approved" && (
          <Button
            style={{ marginLeft: "auto" }}
            type="primary"
            loading={assigning} // Show loading spinner while assigning
            onClick={handleAssignStaff}
          >
            Assign Staff
          </Button>
        )} */}
      </div>
      {/* {data.status === 'pending' && ( */}
      <div style={{ marginLeft: "auto" }}></div>
      {/* )} */}
    </div>
  );
};

export default SARequestDetails;
