import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const LineChartComponent = ({ data, title }) => {
  return (
    <ResponsiveContainer width="100%" height={350}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <YAxis />
        <Tooltip />
        <Legend />
        {title && (
          <text x={200} y={10} textAnchor="middle" dominantBaseline="hanging">
            {title}
          </text>
        )}
        <Line
          type="monotone"
          dataKey="requests"
          stroke="#1890ff"
          fill="rgba(24, 144, 255, 0.2)"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartComponent;
