// services/api.js (RTK API)
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  baseUrl,
  serviceRequestFileUploadApi,
  newServiceRequestApi,
} from "../../Apis";

export const serviceRequestApi = createApi({
  reducerPath: "serviceRequestApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      } else {
        localStorage.clear();
        window.location.href = "/login"; // Reload to login
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    uploadFile: builder.mutation({
      query: (file) => {
        const formData = new FormData();
        formData.append("file", file);
        console.log(file);
        return {
          url: serviceRequestFileUploadApi,
          method: "POST",
          body: formData,
        };
      },
    }),
    submitRequest: builder.mutation({
      query: (data) => ({
        url: newServiceRequestApi,
        method: "POST",
        body: data,
      }),
    }),
    getRequests: builder.query({
      query: ({ page, limit, search }) => ({
        url: `/requests`,
        method: "GET",
        params: { page, limit, search },
      }),
      providesTags: ["Requests"],
    }),
    getSingleRequest: builder.query({
      query: (id) => ({
        url: `/requests/${id}`,
        method: "GET",
      }),
    }),
    getOperatorRequests: builder.query({
      query: (id) => ({
        url: `/requests/operator`,
        method: "GET",
      }),
    }),
    getOperatorRequestHistory: builder.query({
      query: (id) => ({
        url: `/requests/operator/${id}`,
        method: "GET",
      }),
    }),
    approveRequest: builder.mutation({
      query: ({ id, approvedStatus }) => ({
        url: `/requests/${id}/approved-status`,
        method: "PATCH",
        body: { approvedStatus },
      }),
    }),
    completeRequest: builder.mutation({
      query: ({ id, status }) => ({
        url: `/requests/${id}/status`,
        method: "PATCH",
        body: { status },
      }),
    }),
    assignStaffToRequest: builder.mutation({
      query: ({ id, conciergeIds, peerAmbassadorIds, intake }) => ({
        url: `/requests/${id}/assign-multiple`,
        method: "PATCH",
        body: { conciergeIds, peerAmbassadorIds, intake },
      }),
    }),
    getOperators: builder.query({
      query: ({ page, limit, role, search }) => ({
        url: `/users`,
        method: "GET",
        params: { page, limit, role, search },
      }),
    }),
    approveOperator: builder.mutation({
      query: (id) => ({
        url: `/users/${id}/approved-status`,
        method: "PATCH",
        body: { approvedStatus: "approved" },
      }),
    }),
    getRequestStats: builder.query({
      query: ({ timeFrame }) => ({
        url: `/requests/stats`,
        method: "GET",
        params: { timeFrame },
      }),
    }),
    submitApplicationForm: builder.mutation({
      query: (data) => ({
        url: "/users/submit-form",
        method: "POST",
        body: data,
      }),
    }),
    getApplicationForms: builder.query({
      query: (id) => ({
        url: `/users/forms`,
        method: "GET",
      }),
    }),
    updateApplication: builder.mutation({
      query: ({id, status}) => ({
        url: `/users/${id}/form-status`,
        method: "PATCH",
        body: { status },
      }),
    }),
    deleteRequest: builder.mutation({
      query: (id) => ({
        url: `/requests/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Requests"],
    }),
  }),
});

export const {
  useUploadFileMutation,
  useSubmitRequestMutation,
  useGetRequestsQuery,
  useGetOperatorsQuery,
  useGetOperatorRequestHistoryQuery,
  useGetSingleRequestQuery,
  useApproveRequestMutation,
  useCompleteRequestMutation,
  useApproveOperatorMutation,
  useAssignStaffToRequestMutation,
  useGetRequestStatsQuery,
  useSubmitApplicationFormMutation,
  useGetApplicationFormsQuery,
  useUpdateApplicationMutation,
  useDeleteRequestMutation
} = serviceRequestApi;
