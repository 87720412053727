import React, { useState, useEffect, useRef } from "react";
import { List, Avatar, Typography, Input, Button, Row, Col } from "antd";
import { SendOutlined } from "@ant-design/icons";
import { useParams, useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import {
  useCreateChatMutation,
  useFindChatsForCurrentUserQuery,
  useGetConversationBetweenUsersQuery,
} from "../../RtkQuery/chat/chatApi";
import "./index.css";

const { Title } = Typography;

const ChatsPage = () => {
  const { id } = useParams(); // Recipient ID from URL
  const [activeChat, setActiveChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  const [isMessagesLoaded, setIsMessagesLoaded] = useState(false); // Track if messages are loaded
  const user = JSON.parse(localStorage.getItem("user")); // Logged-in user details
  const navigate = useNavigate();
  const messagesEndRef = useRef(null);

  const [createChat] = useCreateChatMutation();
  const { data: chatList, refetch: refetchChats } =
    useFindChatsForCurrentUserQuery(user._id, {
      refetchOnMountOrArgChange: true,
    });
  const { data: userChat, refetch: refetchMessages } =
    useGetConversationBetweenUsersQuery(
      {
        senderId: user._id,
        recipientId: id,
      },
      { refetchOnMountOrArgChange: true }
    );

  // Initialize socket connection
  const socket = React.useMemo(() => {
    // const socketConnection = io("http://localhost:4000", {
    const socketConnection = io("https://nest-backend.my360tribe.org", {
      query: { token: localStorage.getItem("token") },
    });

    socketConnection.on("connect", () =>
      console.log("Socket connected:", socketConnection.id)
    );
    socketConnection.on("disconnect", () => console.log("Socket disconnected"));

    return socketConnection;
  }, []);

  // WebSocket message listener (single listener)
  useEffect(() => {
    const handleNewMessage = (newMessage) => {
      console.log("new mess ", newMessage);
      // Deduplicate using a unique ID
      setMessages((prevMessages) => {
        const isDuplicate = prevMessages.some(
          (msg) => msg.id === newMessage.id
        );
        if (!isDuplicate) {
          return [...prevMessages, formatMessage(newMessage)];
        }
        return prevMessages;
      });
    };

    socket.on("receiveMessage", handleNewMessage);

    return () => {
      socket.off("receiveMessage", handleNewMessage); // Proper cleanup
    };
  }, [socket]);

  // Format message for consistent display
  const formatMessage = (msg) => ({
    id: msg.id || `${msg.timestamp}_${msg.sender}`, // Unique ID for deduplication
    sender: msg.senderId === user._id ? "You" : msg.senderName || msg.sender,
    recipient: msg.recipientName || msg.recipient,
    message: msg.message,
    timestamp: msg.timestamp,
  });

  // Load messages when userChat data changes, but only once
  useEffect(() => {
    if (userChat && Array.isArray(userChat) && userChat.length > 0) {
      const formattedMessages = userChat.map((msg) => ({
        id: msg._id,
        sender: msg.sender._id === user._id ? "You" : msg.sender.firstName,
        recipient: msg.recipient.firstName,
        message: msg.message,
        timestamp: msg.createdAt,
      }));
      setMessages(formattedMessages);
      setIsMessagesLoaded(true); // Mark as loaded
      setMessageInput("");
    } else if (userChat && Array.isArray(userChat) && userChat.length === 0) {
      setMessageInput("Hey"); // Set input value to "Hey" if no messages
      setMessages([]); // Reset messages
    } else if (!isMessagesLoaded) {
      setMessages([]); // Reset messages if no data
    }
  }, [userChat, id, isMessagesLoaded]);

  // Scroll to the bottom of the message list
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  useEffect(() => {
    if (id && chatList) {
      const chat = chatList.find((chat) => chat._id === id);
      setActiveChat(chat || null); // Fallback to null if chat not found
    }
  }, [id, chatList]);

  const handleChatChange = (id) => {
    navigate(`/intakeCare_chats/${id}`);
    setIsMessagesLoaded(false); // Reset loading flag
    setMessages([]); // Clear current messages
    refetchMessages(); // Fetch new messages
    refetchChats(); // Refresh the chat list
  };

  const handleSendMessage = () => {
    if (messageInput.trim()) {
      const newMessage = {
        id: `${Date.now()}_${user?._id}`, // Generate unique ID for optimistic update
        sender: user?._id,
        recipient: id,
        message: messageInput.trim(),
        timestamp: new Date().toISOString(),
      };

      socket.emit("sendMessage", newMessage);
      createChat(newMessage)
        .unwrap()
        .then(() => {
          refetchChats(); // Ensure the chatList is updated
          refetchMessages();
        });

      setMessageInput("");
      refetchMessages();
    }
  };

  console.log("Messages ", messages);

  const renderChatList = () => (
    <List
      itemLayout="horizontal"
      dataSource={chatList}
      renderItem={(chat) => (
        <List.Item
          className="chat-item"
          onClick={() => handleChatChange(chat?._id)}
          style={{
            backgroundColor:
              activeChat?._id === chat?._id ? "#e8e8e8" : "transparent",
          }}
        >
          <List.Item.Meta
            style={{ alignItems: "center", display: "flex", padding: "0 1rem" }}
            avatar={<Avatar>{chat.firstName.charAt(0)}</Avatar>}
            title={chat.firstName + " " + chat.lastName}
          />
        </List.Item>
      )}
    />
  );

  const renderActiveChat = () => (
    <div className="active-chat">
      <div className="active-chat-header">
        <div>
          {activeChat
            ? activeChat?.firstName + " " + activeChat?.lastName
            : "Select a Chat"}
        </div>
      </div>
      <div className="message-list">
        {messages.map((msg, idx) => (
          <div
            className={`message-wrapper ${
              msg.sender === "You" ? "sent-wrp" : "received-wrp"
            }`}
          >
            <div
              key={idx}
              className={`message ${
                msg.sender === "You" ? "sent" : "received"
              }`}
            >
              {/* <span className="message-sender">{msg.sender}: </span> */}
              <span className="message-content">{msg.message}</span>
            </div>
            <span className="message-timestamp">
              {new Date(msg.timestamp).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </span>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="message-input">
        <Input
          placeholder="Type a message..."
          value={messageInput}
          onChange={(e) => setMessageInput(e.target.value)}
          onPressEnter={handleSendMessage}
        />
        <Button
          type="primary"
          icon={<SendOutlined />}
          onClick={handleSendMessage}
        />
      </div>
    </div>
  );

  return (
    <div className="chats-page">
      <div xs={24} md={8} className="chat-list-container">
        {renderChatList()}
      </div>
      <div xs={24} md={16} className="active-chat-container">
        {renderActiveChat()}
      </div>
    </div>
  );
};

export default ChatsPage;
