import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Divider, Button, message, Spin } from "antd";
import BarChart from "../../../Components/Charts/Barchart";
import LineChart from "../../../Components/Charts/LineChart";
import DynamicTable from "../../../Components/UIKit/Table";
import "./index.css";
import {
  useGetAllUsersQuery,
  useDeleteUserMutation,
  useGetUserStatsQuery,
} from "../../../RtkQuery/users/usersApi";
import { useApproveOperatorMutation } from "../../../RtkQuery/ServiceRequest";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../../Components/UIKit/SearchBar";
import ConfirmationModal from "../../../Components/Modals/ConfirmationModals";

const { Title } = Typography;

const SuperAdminDashboard = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [barChartData, setBarChartData] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  // Bar chart data for monthly registrations
  const { data, isLoading, error, refetch } = useGetAllUsersQuery(
    {
      search: searchTerm,
    },
    { refetchOnMountOrArgChange: true }
  );
  const [approveOperator, { isLoading: approving }] =
    useApproveOperatorMutation();
  const [deleteUser, { isLoading: deleting }] = useDeleteUserMutation();
  const { data: userStats, isLoading: gettingStats } = useGetUserStatsQuery({
    timeFrame: "monthly",
  });

  const navigate = useNavigate();

  // Approve handler
  const handleApprove = async (id) => {
    try {
      const response = await approveOperator(id).unwrap();
      // Handle successful approval

      if (response) {
        message.success("User Approved!");
        refetch();
      }
    } catch (err) {
      // Handle error
      message.error("Failed to approve the User.");
    }
  };
  const handleDelete = async (id) => {
    try {
      const response = await deleteUser(id).unwrap();
      if (response) {
        message.success("User Deleted!");
        refetch();
      }
    } catch (err) {
      // Handle error
      message.error("Failed to delete the User.");
    }
  };

  // Role mapping object
  const roleMapping = {
    admin: "Admin",
    peerAmbassador: "Peer Ambassador",
    concierge: "Concierge",
    operator: "Operator",
    intakeCare: "Intake",
  };

  // Table data for registration requests
  const columns = [
    { title: "First Name", dataIndex: "firstName", key: "firstName" },
    { title: "Last Name", dataIndex: "lastName", key: "lastName" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Contact", dataIndex: "phoneNumber", key: "phoneNumber" },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (role) => roleMapping[role] || role,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <div style={{ display: "flex", gap: "1rem" }}>
          {record.approvedStatus === "pending" && (
            <Button
              type="primary"
              shape="circle"
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="size-6"
                  height="18px"
                  width="18px"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                    clip-rule="evenodd"
                  />
                </svg>
              }
              // icon={<SiTicktick />}
              onClick={() => handleApprove(record._id)}
            />
          )}
          <Button
            type="primary"
            shape="circle"
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="size-6"
                height={"18px"}
                width={"18"}
              >
                <path d="M19.906 9c.382 0 .749.057 1.094.162V9a3 3 0 0 0-3-3h-3.879a.75.75 0 0 1-.53-.22L11.47 3.66A2.25 2.25 0 0 0 9.879 3H6a3 3 0 0 0-3 3v3.162A3.756 3.756 0 0 1 4.094 9h15.812ZM4.094 10.5a2.25 2.25 0 0 0-2.227 2.568l.857 6A2.25 2.25 0 0 0 4.951 21H19.05a2.25 2.25 0 0 0 2.227-1.932l.857-6a2.25 2.25 0 0 0-2.227-2.568H4.094Z" />
              </svg>
            }
            // icon={<FaFolderOpen />}
            onClick={() => navigate(`/superAdmin_userDetails/${record._id}`)}
          />
          {/* <Button
            shape="circle"
            color="danger"
            variant="solid"
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="size-6"
                height={"18px"}
                width={"18px"}
              >
                <path
                  fill-rule="evenodd"
                  d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                  clip-rule="evenodd"
                />
              </svg>
            }
            // icon={<MdDelete />}
            onClick={() => handleDelete(record._id)}
          /> */}
          <ConfirmationModal type = "delete" message="Are you sure you want to delete this user?" onConfirm={() => handleDelete(record._id)}/>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (userStats) {
      console.log("userStats:", userStats); // Debug log
      const barData = Object.keys(userStats.months).map((month) => ({
        month: new Date(2024, month - 1).toLocaleString("default", {
          month: "short",
        }),
        operator: userStats.months[month].operator,
        concierge: userStats.months[month].concierge,
        peerAmbassador: userStats.months[month].peerAmbassador,
        intakeCare: userStats.months[month].intakeCare,
      }));

      // console.log(barData);
      setBarChartData(barData);

      const lineData = Object.keys(userStats.months).map((month) => ({
        month: new Date(2024, month - 1).toLocaleString("default", {
          month: "short",
        }),
        requests: userStats.months[month].requests,
      }));

      // console.log(lineData);
      setLineChartData(lineData);
    }
  }, [userStats]);

  useEffect(() => {
    const fData = data?.filter((user) => user.role !== "superAdmin") || [];
    setFilteredData(fData);
  }, [data]);

  return (
    <div className="super-admin-dashboard">
      <Title level={2}>Dashboard</Title>
      <Divider />
      {gettingStats ? (
        <Spin
          tip="Loading..."
          style={{ display: "block", marginTop: "16px" }}
        />
      ) : (
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <BarChart
              data={barChartData}
              xKey="month"
              bars={[
                { key: "operator", fill: "#054a91" },
                { key: "concierge", fill: "#3e7cb1" },
                { key: "peerAmbassador", fill: "#81a4cd" },
                { key: "intakeCare", fill: "#dbe4ee" },
              ]}
              title="Monthly Registrations by Role"
            />
          </Col>
          <Col span={12}>
            <LineChart
              data={lineChartData}
              xKey="month"
              lineKey="requests"
              lineColor="#1890ff"
              title="Monthly Services Requested"
            />
          </Col>
        </Row>
      )}
      <div style={{ marginTop: "2rem" }}>
        <Title level={3}>Registration Requests</Title>
        <Divider />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            marginBottom: "24px",
          }}
        >
          <div>
            <h3>Users</h3>
          </div>
          <div>
            <SearchBar
              placeholder="Search users..."
              onSearch={(term) => setSearchTerm(term)}
            />
          </div>
        </div>
        {isLoading ? (
          <Spin
            tip="Loading..."
            style={{ display: "block", marginTop: "16px" }}
          />
        ) : (
          <DynamicTable columns={columns} data={filteredData} />
        )}
      </div>
    </div>
  );
};

export default SuperAdminDashboard;
