import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../Apis";

export const notesApi = createApi({
  reducerPath: "notesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      headers.set("authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["Notes"], // Tag for cache invalidation
  endpoints: (builder) => ({
    // Fetch all notes
    getNotes: builder.query({
      query: () => ({
        url: "/notes",
        method: "GET",
      }),
      providesTags: ["Notes"], // Cache all notes with the 'Notes' tag
    }),
    // Fetch a single note by for request
    getReqNoteById: builder.query({
      query: (id) => ({
        url: `/notes/request/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "Notes", id }], // Cache specific note
    }),
    getNoteByOperatorId: builder.query({
      query: (id) => ({
        url: `/notes/operator/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "Notes", id }], // Cache specific note
    }),
    getNoteById: builder.query({
      query: (id) => ({
        url: `/notes/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "Notes", id }], // Cache specific note
    }),
    // Create a new note
    createNote: builder.mutation({
      query: (newNote) => ({
        url: "/notes",
        method: "POST",
        body: newNote,
      }),
      invalidatesTags: ["Notes"], // Invalidate 'Notes' to refresh list
    }),
    // Update an existing note
    updateNote: builder.mutation({
      query: ({ id, ...updatedNote }) => ({
        url: `/notes/${id}`,
        method: "PATCH",
        body: updatedNote,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Notes", id }], // Invalidate specific note cache
    }),
    // Delete a note
    deleteNote: builder.mutation({
      query: (id) => ({
        url: `/notes/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Notes"], // Invalidate 'Notes' cache to refresh list
    }),
  }),
});

export const {
  useGetNotesQuery,
  useGetNoteByIdQuery,
  useGetNoteByOperatorIdQuery,
  useGetReqNoteByIdQuery,
  useCreateNoteMutation,
  useUpdateNoteMutation,
  useDeleteNoteMutation,
} = notesApi;
