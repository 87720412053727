import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Divider, Typography, Spin, Alert, Tag, Table, Tabs } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useGetApplicationFormsQuery } from "../../../RtkQuery/ServiceRequest";

const AdminApplicationRequests = () => {
  const navigate = useNavigate();
  const { Title, Paragraph } = Typography;
  const { TabPane } = Tabs;
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  // Fetch requests using RTK Query
  const { data, error, isLoading } = useGetApplicationFormsQuery();

  // Filter data based on form type
  const peerAmbassadorForms = data?.filter((item) => item.name);
  const servicePartnerForms = data?.filter((item) => item.organizationName);

  // Columns for Peer Ambassador/Concierge forms
  const peerAmbassadorColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Form Status",
      dataIndex: "formStatus",
      key: "formStatus",
      render: (formStatus) => (
        <Tag
          color={
            formStatus === "pending"
              ? "red"
              : formStatus === "approved"
              ? "green"
              : formStatus === "formSubmitted"
              ? "orange"
              : "gray"
          }
        >
          {formStatus === "pending"
            ? "Pending"
            : formStatus === "approved"
            ? "Approved"
            : formStatus === "formSubmitted"
            ? "Pending"
            : "Rejected"}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button
          type="primary"
          icon={<EyeOutlined />}
          onClick={() => navigate(`/admin_applicationDetails/${record.userId}`)}
        >
          View Details
        </Button>
      ),
    },
  ];

  // Columns for Service Partner forms
  const servicePartnerColumns = [
    {
      title: "Organization Name",
      dataIndex: "organizationName",
      key: "organizationName",
    },
    {
      title: "Contact Person",
      dataIndex: "pointOfContactName",
      key: "pointOfContactName",
    },
    {
      title: "Phone",
      dataIndex: "organizationPhone",
      key: "organizationPhone",
    },
    {
      title: "Contact Email",
      dataIndex: "pointOfContactEmail",
      key: "pointOfContactEmail",
    },
    {
      title: "Form Status",
      dataIndex: "formStatus",
      key: "formStatus",
      render: (formStatus) => (
        <Tag
          color={
            formStatus === "pending"
              ? "red"
              : formStatus === "approved"
              ? "green"
              : formStatus === "formSubmitted"
              ? "orange"
              : "gray"
          }
        >
          {formStatus === "pending"
            ? "Pending"
            : formStatus === "approved"
            ? "Approved"
            : formStatus === "formSubmitted"
            ? "Pending"
            : "Rejected"}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button
          type="primary"
          icon={<EyeOutlined />}
          onClick={() => navigate(`/superAdmin_applicationDetails/${record.userId}`)}
        >
          View Details
        </Button>
      ),
    },
  ];

  return (
    <div style={{ padding: "16px" }}>
      <Title level={2}>Applications</Title>
      <Divider />
      {isLoading && (
        <div style={{ textAlign: "center", padding: "24px 0" }}>
          <Spin tip="Loading..." style={{ display: "block", marginTop: "16px" }} />
        </div>
      )}

      {error && (
        <Alert
          message="Error"
          description="An error occurred while fetching requests. Please try again later."
          type="error"
          showIcon
          style={{ marginBottom: "16px" }}
        />
      )}

      {!isLoading && !error && (
        <Tabs defaultActiveKey="1">
          {/* Peer Ambassador/Concierge Forms */}
          <TabPane tab="Peer Ambassador/Concierge Forms" key="1">
            <Table
              style={{ borderRadius: "10px", border: "2px solid #f0f0f0" }}
              columns={peerAmbassadorColumns}
              dataSource={peerAmbassadorForms}
              rowKey="id"
              pagination={{ pageSize: 10 }}
            />
          </TabPane>

          {/* Service Partner Forms */}
          <TabPane tab="Service Partner Forms" key="2">
            <Table
              style={{ borderRadius: "10px", border: "2px solid #f0f0f0" }}
              columns={servicePartnerColumns}
              dataSource={servicePartnerForms}
              rowKey="id"
              pagination={{ pageSize: 10 }}
            />
          </TabPane>
        </Tabs>
      )}
    </div>
  );
};

export default AdminApplicationRequests;
