import React, { useState } from "react";
import { Modal, Button } from "antd";

const FileViewerModal = ({ fileUrl }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const isImage = (url) => {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
    return imageExtensions.some(ext => url?.toLowerCase().endsWith(ext));
  };

  const isPDF = (url) => url?.toLowerCase().endsWith('pdf');

  return (
    <>
      <a onClick={showModal}>
        View File
      </a>
      <Modal
        title="File Viewer"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          // <Button key="download" type="primary" href={fileUrl} target="_blank">
          //   Download
          // </Button>,
          <Button key="close" onClick={handleCancel}>
            Close
          </Button>,
        ]}
        width={800}
      >
        {isImage(fileUrl) ? (
          <img src={fileUrl} alt="Preview" style={{ maxWidth: "100%" }} />
        ) : isPDF(fileUrl) ? (
          <iframe
            src={fileUrl}
            title="PDF Viewer"
            style={{ width: "100%", height: "500px", border: "none" }}
          />
        ) : (
          <p>
            This file type is not directly previewable. Click the download
            button to access the file.
          </p>
        )}
      </Modal>
    </>
  );
};

export default FileViewerModal;
