import React, { useState, useEffect } from "react";
import { Button, Divider, Typography, Table, message, Spin } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import {
  useGetOperatorsQuery,
  useApproveOperatorMutation,
} from "../../../RtkQuery/ServiceRequest";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../../Components/UIKit/SearchBar";

const IntakeCareSignupRequests = () => {
  const { Title } = Typography;
  const role = localStorage.getItem("role");
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [operatorData, setOperatorData] = useState([]);

  // Fetch data with pagination
  const { data, error, isLoading, refetch } = useGetOperatorsQuery(
    {
      page: currentPage,
      limit: pageSize,
      search: searchTerm,
      role: "operator",
    },
    { refetchOnMountOrArgChange: true }
  );

  const [approveOperator, { isLoading: approving }] =
    useApproveOperatorMutation();

  // Table columns
  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
    },
    {
      title: "Branch",
      dataIndex: "branch",
      key: "branch",
    },
    {
      title: "Transition Date",
      dataIndex: "transitionDate",
      key: "transitionDate",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "Status",
      // dataIndex: 'state',
      key: "status",
      render: (_, record) =>
        record.isApproved ? (
          <div style={{ color: "green" }}>Verified</div>
        ) : (
          <div style={{ color: "red" }}>Not Verified</div>
        ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1 }}>
              {record.approvedStatus === "approved" ? (
                <div style={{ color: "green" }}>Approved</div>
              ) : (
                <Button
                  type="primary"
                  icon={<CheckOutlined />}
                  onClick={() => handleApprove(record._id)}
                >
                  Approve
                </Button>
              )}
            </div>
            <Button
              type="primary"
              style={{ flex: 1 }}
              onClick={() =>
                navigate(`/intakeCare/operatorDetails/${record._id}`)
              }
            >
              View Details
            </Button>
          </div>
        );
      },
    },
  ];

  // Approve handler
  const handleApprove = async (id) => {
    try {
      console.log(id);
      const response = await approveOperator(id).unwrap();
      // Handle successful approval

      if (response) {
        message.success("operator Approved!");
        refetch();
      }
    } catch (err) {
      // Handle error
      message.error("Failed to approve the OPerator.");
    }
  };

  // Handle page change
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  useEffect(() => {
    if (data) {
      const opData = data.filter((item) => item.role === "operator") || [];
      setOperatorData(opData);
    }
  }, [data]);

  return (
    <div style={{ padding: "16px" }}>
      <Title level={2}>Operators</Title>
      <Divider />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          marginBottom: "24px",
        }}
      >
        <div>
          <h3>All Operators</h3>
        </div>
        <div>
          <SearchBar
            placeholder="Search operators..."
            onSearch={(term) => setSearchTerm(term)}
          />
        </div>
      </div>

      {isLoading ? (
        <Spin
          tip="Loading..."
          style={{ display: "block", marginTop: "16px" }}
        />
      ) : error ? (
        <p style={{ color: "red" }}>
          Failed to load data. Please try again later.
        </p>
      ) : (
        <Table
          style={{ border: "2px solid #f0f0f0", borderRadius: "10px" }}
          columns={columns}
          dataSource={data} // Assuming items are under 'items' key
          loading={isLoading}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: data?.total, // Assuming 'total' is in the response
            onChange: handlePageChange, // Page change handler
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30", "50"],
          }}
        />
      )}
    </div>
  );
};

export default IntakeCareSignupRequests;
