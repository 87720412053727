import React, { useEffect } from "react";
import ChatsPage from "../../../Components/ChatPage";
import { useNavigate, useParams } from "react-router-dom";

export default function PAChats() {
  const navigate = useNavigate();

  const formStatus = localStorage.getItem("formStatus");
  useEffect(() => {
    if (formStatus !== "approved") {
      navigate("/concierge_dashboard");
    }
  }, [formStatus]);
  
  return (
    <div>
      <h1>Chats</h1>
      <ChatsPage />
    </div>
  );
}
