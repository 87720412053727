import React, { useEffect } from "react";
import ChatsPage from "../../../Components/ChatPage";
import { useNavigate } from "react-router-dom";

const ConciergeChatPage = () => {

  const navigate = useNavigate();

  const formStatus = localStorage.getItem("formStatus");
  useEffect(() => {
    if (formStatus !== "approved") {
      navigate("/concierge_dashboard");
    }
  }, [formStatus]);

  return (
    <div>
      <h1>My Chats</h1>
      <ChatsPage />
    </div>
  );
};

export default ConciergeChatPage;
