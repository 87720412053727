import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import WrapperLayout from "./Layout";
import OperatorDashboard from "./Pages/Operator/Dashboard";
import OperatorProfilePage from "./Pages/Operator/Profile";
import ServiceRequestForm from "./Pages/Operator/Service form";
import OperatorChats from "./Pages/Operator/Chats";
import IntakeCareDashboard from "./Pages/Intakecare/Dashboard/indes";
import IntakeCareDetails from "./Pages/Intakecare/RequestDetails";
import ConciergeDashboard from "./Pages/Concierge/Dashboard";
import ConciergeChatPage from "./Pages/Concierge/ChatPage";
import PADashboard from "./Pages/PeerAmbassador/Dashboard";
import ICProfilePage from "./Pages/Intakecare/Profile";
import SuperAdminDashboard from "./Pages/SuperAdmin/Dashboard";
import SignIn from "./Pages/Auth/signIn";
import SignUp from "./Pages/Auth/signUp";
import PrivateRoute from "./routes/private";
import PublicRoutes from "./routes/public";
import SignupSuccess from "./Pages/SignupSuccessPage";
import IntakeCareSignupRequests from "./Pages/Intakecare/SignupRequests/indes";
import VerificationPage from "./Pages/Auth/verify";
import RequestDetailsPage from "./Pages/Concierge/RequestDetails";
import OperatorListPage from "./Pages/Concierge/OperatorList";
import OperatorDetails from "./Pages/Concierge/Operatordetails";
import NotesPage from "./Pages/Concierge/NotesPage";
import OperatorRequestDetailsPage from "./Pages/Operator/RequestDetails";
import IntOperatorDetails from "./Pages/Intakecare/OperatorDetails";
import PARequestDetailsPage from "./Pages/PeerAmbassador/RequestDetails";
import PAProfilePage from "./Pages/PeerAmbassador/Profile";
import ConProfilePage from "./Pages/Concierge/Profile";
import CreateUserPage from "./Pages/SuperAdmin/CreateUser";
import IntakeChats from "./Pages/Intakecare/Chats";
import SAOperatorDetails from "./Pages/SuperAdmin/OperatorDetails";
import SARequests from "./Pages/SuperAdmin/Requests/indes";
import SARequestDetails from "./Pages/SuperAdmin/RequestDetails";
import SAChats from "./Pages/SuperAdmin/Chats";
import AdminChats from "./Pages/Admin/Chats";
import AdminCreateUserPage from "./Pages/Admin/CreateUser";
import AdminDashboard from "./Pages/Admin/Dashboard";
import AdminOperatorDetails from "./Pages/Admin/OperatorDetails";
import AdminRequestDetails from "./Pages/Admin/RequestDetails";
import AdminRequests from "./Pages/Admin/Requests/indes";
import PAChats from "./Pages/PeerAmbassador/Chats";
import AdminProfilePage from "./Pages/Admin/Profile";
import SAProfilePage from "./Pages/SuperAdmin/Profile";
import ForgotPassword from "./Pages/Auth/forgotPassword";
import ResetPassword from "./Pages/Auth/resetPassword";
import SAApplicationRequests from "./Pages/SuperAdmin/Applications";
import SAApplicationDetails from "./Pages/SuperAdmin/ApplicationDetails";
import AdminApplicationRequests from "./Pages/Admin/Applications";
import AdminApplicationDetails from "./Pages/Admin/ApplicationDetails";
import SPDashboard from "./Pages/ServiceProvider/Dashboard/indes";
import SPChats from "./Pages/ServiceProvider/Chats";
import SPProfilePage from "./Pages/ServiceProvider/Profile";

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Public routes */}
        <Route path="/verify" element={<VerificationPage />} />

        <Route element={<PublicRoutes />}>
          <Route path="/login" element={<SignIn />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signup_success" element={<SignupSuccess />} />
        </Route>

        {/* Default Route */}
        <Route path="/" element={<Navigate to="/signup" />} />

        {/* Private routes */}
        <Route path="/" element={<WrapperLayout />}>
          {/* Operator Routes */}
          <Route
            path="/operator_dashboard"
            element={
              <PrivateRoute
                element={<OperatorDashboard />}
                allowedRoles={["operator", "superAdmin"]}
              />
            }
          />
          <Route
            path="/operator_profile"
            element={
              <PrivateRoute
                element={<OperatorProfilePage />}
                allowedRoles={["operator", "superAdmin"]}
              />
            }
          />
          <Route
            path="/service_request_form"
            element={
              <PrivateRoute
                element={<ServiceRequestForm />}
                allowedRoles={["operator", "superAdmin"]}
              />
            }
          />
          <Route
            path="/operator/request/:id"
            element={
              <PrivateRoute
                element={<OperatorRequestDetailsPage />}
                allowedRoles={["operator", "superAdmin"]}
              />
            }
          />
          <Route
            path="/operator_chats/:id?"
            element={
              <PrivateRoute
                element={<OperatorChats />}
                allowedRoles={["operator", "superAdmin"]}
              />
            }
          />

          {/* IntakeCare Routes */}
          <Route
            path="/intakeCare_dashboard"
            element={
              <PrivateRoute
                element={<IntakeCareDashboard />}
                allowedRoles={["intakeCare", "superAdmin"]}
              />
            }
          />
          <Route
            path="/intakeCare_chats/:id?"
            element={
              <PrivateRoute
                element={<IntakeChats />}
                allowedRoles={["intakeCare", "superAdmin"]}
              />
            }
          />
          <Route
            path="/intakeCare/request/:id"
            element={
              <PrivateRoute
                element={<IntakeCareDetails />}
                allowedRoles={["intakeCare", "superAdmin"]}
              />
            }
          />
          <Route
            path="/intakeCare_profile"
            element={
              <PrivateRoute
                element={<ICProfilePage />}
                allowedRoles={["intakeCare", "superAdmin"]}
              />
            }
          />
          <Route
            path="/signup_requests"
            element={
              <PrivateRoute
                element={<IntakeCareSignupRequests />}
                allowedRoles={["intakeCare", "superAdmin"]}
              />
            }
          />
          <Route
            path="/intakeCare/operatorDetails/:id"
            element={
              <PrivateRoute
                element={<IntOperatorDetails />}
                allowedRoles={["intakeCare", "superAdmin"]}
              />
            }
          />

          {/* Concierge Routes */}
          <Route
            path="/concierge_dashboard"
            element={
              <PrivateRoute
                element={<ConciergeDashboard />}
                allowedRoles={["concierge", "superAdmin"]}
              />
            }
          />
          <Route
            path="/concierge_profile"
            element={
              <PrivateRoute
                element={<ConProfilePage />}
                allowedRoles={["concierge", "superAdmin"]}
              />
            }
          />
          <Route
            path="/operators"
            element={
              <PrivateRoute
                element={<OperatorListPage />}
                allowedRoles={["concierge", "superAdmin"]}
              />
            }
          />
          <Route
            path="/operator_details/:id"
            element={
              <PrivateRoute
                element={<OperatorDetails />}
                allowedRoles={["concierge", "superAdmin"]}
              />
            }
          />
          <Route
            path="/concierge/request/:id"
            element={
              <PrivateRoute
                element={<RequestDetailsPage />}
                allowedRoles={["concierge", "superAdmin"]}
              />
            }
          />
          <Route
            path="/notes/:id"
            element={
              <PrivateRoute
                element={<NotesPage />}
                allowedRoles={["concierge", "intakeCare", "superAdmin"]}
              />
            }
          />
          <Route
            path="/concierge_chats/:id?"
            element={
              <PrivateRoute
                element={<ConciergeChatPage />}
                allowedRoles={["concierge", "superAdmin"]}
              />
            }
          />

          {/* Peer Ambassador Routes */}
          <Route
            path="/peerAmbassador_dashboard"
            element={
              <PrivateRoute
                element={<PADashboard />}
                allowedRoles={["peerAmbassador"]}
              />
            }
          />
          <Route
            path="/peerAmbassador_profile"
            element={
              <PrivateRoute
                element={<PAProfilePage />}
                allowedRoles={["peerAmbassador"]}
              />
            }
          />
          <Route
            path="/peerAmbassador_chats/:id?"
            element={
              <PrivateRoute
                element={<PAChats />}
                allowedRoles={["peerAmbassador"]}
              />
            }
          />
          <Route
            path="/peerAmbassador_operatorDetails/:id"
            element={
              <PrivateRoute
                element={<PARequestDetailsPage />}
                allowedRoles={["peerAmbassador"]}
              />
            }
          />

          {/* SuperAdmin Routes */}
          <Route
            path="/superAdmin_dashboard"
            element={
              <PrivateRoute
                element={<SuperAdminDashboard />}
                allowedRoles={["superAdmin"]}
              />
            }
          />
          <Route
            path="/superAdmin_userDetails/:id"
            element={
              <PrivateRoute
                element={<SAOperatorDetails />}
                allowedRoles={["superAdmin"]}
              />
            }
          />
          <Route
            path="/superAdmin_createUser"
            element={
              <PrivateRoute
                element={<CreateUserPage />}
                allowedRoles={["superAdmin"]}
              />
            }
          />
          <Route
            path="/superAdmin_requests"
            element={
              <PrivateRoute
                element={<SARequests />}
                allowedRoles={["superAdmin"]}
              />
            }
          />
          <Route
            path="/superAdmin_requestDetails/:id"
            element={
              <PrivateRoute
                element={<SARequestDetails />}
                allowedRoles={["superAdmin"]}
              />
            }
          />
          <Route
            path="/superAdmin_applications/"
            element={
              <PrivateRoute
                element={<SAApplicationRequests />}
                allowedRoles={["superAdmin"]}
              />
            }
          />
          <Route
            path="/superAdmin_applicationDetails/:id"
            element={
              <PrivateRoute
                element={<SAApplicationDetails />}
                allowedRoles={["superAdmin"]}
              />
            }
          />
          <Route
            path="/superAdmin_chats/:id?"
            element={
              <PrivateRoute
                element={<SAChats />}
                allowedRoles={["superAdmin"]}
              />
            }
          />
          <Route
            path="/superAdmin_profile"
            element={
              <PrivateRoute
                element={<SAProfilePage />}
                allowedRoles={["superAdmin"]}
              />
            }
          />
          {/* Admin Routes */}
          <Route
            path="/admin_dashboard"
            element={
              <PrivateRoute
                element={<AdminDashboard />}
                allowedRoles={["admin", "superAdmin"]}
              />
            }
          />
          <Route
            path="/admin_userDetails/:id"
            element={
              <PrivateRoute
                element={<AdminOperatorDetails />}
                allowedRoles={["admin", "superAdmin"]}
              />
            }
          />
          <Route
            path="/admin_createUser"
            element={
              <PrivateRoute
                element={<AdminCreateUserPage />}
                allowedRoles={["admin", "superAdmin"]}
              />
            }
          />
          <Route
            path="/admin_requests"
            element={
              <PrivateRoute
                element={<AdminRequests />}
                allowedRoles={["admin", "superAdmin"]}
              />
            }
          />
          <Route
            path="/admin_requestDetails/:id"
            element={
              <PrivateRoute
                element={<AdminRequestDetails />}
                allowedRoles={["admin", "superAdmin"]}
              />
            }
          />
          <Route
            path="/admin_profile"
            element={
              <PrivateRoute
                element={<AdminProfilePage />}
                allowedRoles={["admin", "superAdmin"]}
              />
            }
          />
          <Route
            path="/admin_chats/:id?"
            element={
              <PrivateRoute
                element={<AdminChats />}
                allowedRoles={["admin", "superAdmin"]}
              />
            }
          />
          <Route
            path="/admin_applications/"
            element={
              <PrivateRoute
                element={<AdminApplicationRequests />}
                allowedRoles={["admin"]}
              />
            }
          />
          <Route
            path="/admin_applicationDetails/:id"
            element={
              <PrivateRoute
                element={<AdminApplicationDetails />}
                allowedRoles={["admin"]}
              />
            }
          />
          {/* Serviuce Provider Routes */}
          <Route
            path="/servicePartner_dashboard"
            element={
              <PrivateRoute
                element={<SPDashboard />}
                allowedRoles={["servicePartner"]}
              />
            }
          />
          <Route
            path="/servicePartner_chats/:id?"
            element={
              <PrivateRoute
                element={<SPChats />}
                allowedRoles={["servicePartner"]}
              />
            }
          />
          <Route
            path="/servicePartner_profile"
            element={
              <PrivateRoute
                element={<SPProfilePage />}
                allowedRoles={["servicePartner"]}
              />
            }
          />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
