import React from "react";
import { Table } from "antd";

const DynamicTable = ({ columns, data, loading, pagination }) => {
  return (
    <Table style={{ borderRadius: "10px", border: "2px solid #f0f0f0" }}
      columns={columns} 
      dataSource={data} 
      loading={loading} 
      rowKey="id" 
      pagination= {pagination }
    />
  );
};

export default DynamicTable;
