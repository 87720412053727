import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../Apis";

export const usersApi = createApi({
  reducerPath: "usersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      } else {
        localStorage.clear();
        window.location.href = "/login"; // Reload to login
      }
      return headers;
    },
  }),
  tagTypes: ["Users"],
  endpoints: (builder) => ({
    // Get all users
    getAllUsers: builder.query({
      query: ({ search }) => ({
        url: `/users`,
        method: "GET",
        params: { search },
      }),
      providesTags: ["Users"],
    }),
    // Get user by ID
    getUserById: builder.query({
      query: (id) => `/users/${id}`,
      providesTags: (result, error, id) => [{ type: "Users", id }],
    }),
    // Create a new user
    createUser: builder.mutation({
      query: (newUser) => ({
        url: "/users",
        method: "POST",
        body: newUser,
      }),
      invalidatesTags: ["Users"],
    }),
    // Update a user
    updateUser: builder.mutation({
      query: ({ id, ...updatedUser }) => ({
        url: `/users/${id}`,
        method: "PATCH",
        body: updatedUser,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Users", id }],
    }),
    // Delete a user
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `/users/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Users"],
    }),
    assignStaffToOperator: builder.mutation({
      query: ({ id, conciergeIds, peerAmbassadorIds, intake }) => ({
        url: `/users/${id}/assign`,
        method: "PATCH",
        body: { conciergeIds, peerAmbassadorIds, intake },
      }),
    }),
    unAssignStaffToOperator: builder.mutation({
      query: ({ id, conciergeIds, peerAmbassadorIds, intake }) => ({
        url: `/users/${id}/unassign`,
        method: "PATCH",
        body: { conciergeIds, peerAmbassadorIds, intake },
      }),
    }),
    getUserStats: builder.query({
      query: ({ timeFrame }) => ({
        url: `/users/stats`,
        method: "GET",
        params: { timeFrame },
      }),
    }),
  }),
});

export const {
  useGetAllUsersQuery,
  useGetUserByIdQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useAssignStaffToOperatorMutation,
  useUnAssignStaffToOperatorMutation,
  useGetUserStatsQuery,
} = usersApi;
