import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Select, Button, Typography } from "antd";
import { useSubmitApplicationFormMutation } from "../../../RtkQuery/ServiceRequest";
import ConfirmationModal from "../ConfirmationModals";
import { useNavigate } from "react-router-dom";

const { TextArea } = Input;
const { Title } = Typography;

const PAFirstLoginModal = ({setState}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()

  const formStatus = localStorage.getItem("formStatus");

  const [submitApplicationForm] =
    useSubmitApplicationFormMutation();

  // Form instance
  const [form] = Form.useForm();

  // States for dropdown selections
  const [transitionSelected, setTransitionSelected] = useState("no");
  const [communitySelected, setCommunitySelected] = useState("no");

  useEffect(() => {
    if (formStatus === "pending") {
      setIsModalOpen(true);
    }
  }, [formStatus]);

  const handleFormSubmit = async (formData) => {
    setLoading(true);
    // Combine and update the values for transitionServicesUsed
    formData.transitionServicesUsed =
      formData.transitionServicesUsed === "yes"
        ? `${formData.transitionServicesUsed}, ${
            formData.transitionTextarea || ""
          }`
        : formData.transitionServicesUsed;

    // Combine and update the values for nonProfitConnections
    formData.nonProfitConnections =
      formData.nonProfitConnections === "yes"
        ? `${formData.nonProfitConnections}, ${
            formData.communityTextarea || ""
          }`
        : formData.nonProfitConnections;

    // Remove unwanted fields from formData
    delete formData.transitionTextarea;
    delete formData.communityTextarea;

    try {
      await submitApplicationForm(formData).unwrap(); // Call the RTK mutation and unwrap the response
      setLoading(false);
      localStorage.setItem("formStatus", "formSubmitted");
      setState(true)
      setIsModalOpen(false);
    } catch (error) {
      console.error("Submission error:", error);
    }

    console.log(formData);
  };

  const handleFormCancel =  () => {
    localStorage.clear() 
    navigate('/')
  }

  return (
    <Modal
      title={
        <div style={{display:'flex', justifyContent:'space-between', alignContent:'center'}}>
          <div>
          <Title level={3}>Application Form</Title>
          <p style={{ color: "gray", fontWeight: "lighter", fontSize: "14px" }}>
            Please fill this form to request account approval.
          </p>
          </div>
          <ConfirmationModal type="warning" message="You will be logged out, continue?" onConfirm={handleFormCancel}/>
        </div>
      }
      open={isModalOpen}
      footer={null}
      closable={false}
      centered
      width={700}
      bodyStyle={{
        maxHeight: "80vh", // Fixed modal height
        overflowY: "auto", // Scrollable content
        padding: "20px",
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFormSubmit}
        initialValues={{
          transitionExperience: "No",
          communityConnections: "No",
        }}
      >
        {/* Contact Information */}
        <Title level={4}>Contact Information</Title>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please enter your name." }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Current Address"
          name="currentAddress"
          rules={[{ required: true, message: "Please enter your address." }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Phone"
          name="phone"
          rules={[
            { required: true, message: "Please enter your phone number." },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Please enter your email." }]}
        >
          <Input type="email" />
        </Form.Item>
        <Form.Item
          label="Preferred Method of Contact"
          name="preferredContactMethod"
          rules={[
            {
              required: true,
              message: "Please select a preferred contact method.",
            },
          ]}
        >
          <Select>
            <Select.Option value="phone">Phone</Select.Option>
            <Select.Option value="email">Email</Select.Option>
          </Select>
        </Form.Item>

        {/* Military Service */}
        <Title level={4}>Military Service</Title>
        <Form.Item
          label="Branch of Service"
          name="branchOfService"
          rules={[
            { required: true, message: "Please enter your branch of service." },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Date of Separation"
          name="dateOfSeparation"
          rules={[
            { required: true, message: "Please enter the date of separation." },
          ]}
        >
          <Input type="date" />
        </Form.Item>

        {/* Program Interest */}
        <Title level={4}>Program Interest</Title>
        <Form.Item
          label="How did you hear about us?"
          name="howDidYouHearAboutUs"
          rules={[{ required: true, message: "Please answer this question." }]}
        >
          <TextArea rows={3} />
        </Form.Item>
        <Form.Item
          label="Why would you like to be a Peer Ambassador?"
          name="whyWouldYouLikeToBeAPeerAmbassadorOrIntakeCare"
          rules={[{ required: true, message: "Please answer this question." }]}
        >
          <TextArea rows={3} />
        </Form.Item>
        <Form.Item
          label="How many hours a month can you commit to your Operators?"
          name="hoursCommitment"
          rules={[{ required: true, message: "Please answer this question." }]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          label="How many Operators would you be able to work with at one time if asked?"
          name="maxOperators"
          rules={[{ required: true, message: "Please answer this question." }]}
        >
          <Input type="number" />
        </Form.Item>

        {/* Transition Experience */}
        <Title level={4}>Transition Experience</Title>
        <Form.Item
          label="Did you work with any transition services/organizations during your transition?"
          style={{ marginBottom: 0 }}
        >
          <Form.Item name="transitionServicesUsed" noStyle>
            <Select
              // onChange={(value) => {
              //   setTransitionSelected(value); // Update state
              // }}
              onChange={(value) => setTransitionSelected(value)}
            >
              <Select.Option value="yes">Yes</Select.Option>
              <Select.Option value="no">No</Select.Option>
            </Select>
          </Form.Item>
        </Form.Item>
        {transitionSelected === "yes" && (
          <Form.Item label="If yes, which ones?" name={"transitionTextarea"}>
            <TextArea rows={2} />
          </Form.Item>
        )}

        {/* Support and Comfort Levels */}
        <Title level={4}>Support and Comfort Levels</Title>
        <Form.Item
          label="What areas of transition do you feel you can best support?"
          name="areasToSupport"
        >
          <TextArea rows={2} />
        </Form.Item>
        <Form.Item
          label="What area(s)/topics are you uncomfortable with?"
          name="areasUncomfortable"
        >
          <TextArea rows={2} />
        </Form.Item>

        {/* Community Connections */}
        <Title level={4}>Community Connections</Title>
        <Form.Item
          label="Are there any non-profits or service providers you have a relationship with?"
          style={{ marginBottom: 0 }}
        >
          <Form.Item name="nonProfitConnections" noStyle>
            <Select
              onChange={(value) => {
                setCommunitySelected(value); // Update state
              }}
            >
              <Select.Option value="yes">Yes</Select.Option>
              <Select.Option value="no">No</Select.Option>
            </Select>
          </Form.Item>
        </Form.Item>
        {communitySelected === "yes" && (
          <Form.Item label="If yes, which ones?" name={"communityTextarea"}>
            <TextArea rows={2} />
          </Form.Item>
        )}

        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          block
          style={{ margin: "1rem 0" }}
        >
          Submit
        </Button>
      </Form>
    </Modal>
  );
};

export default PAFirstLoginModal;
