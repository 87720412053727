import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Radio,
  Upload,
  Typography,
  Row,
  Col,
  message,
  Divider,
  Spin,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import "./index.css";
import axios from "axios";
import {
  useUploadFileMutation,
  useSubmitRequestMutation,
} from "../../../RtkQuery/ServiceRequest";
import { baseUrl, serviceRequestFileUploadApi } from "../../../Apis";
import dayjs from "dayjs";

const { Title, Paragraph } = Typography;

const supportOptions = ["Peer Support", "Transition", "Health", "Employment","Other"];
const mentalHealthOptions = [
  "Individual Therapy",
  "Family/Marital Therapy",
  "Mental",
  "Eye Movement Desensitization and Reprocessing (EMDR)",
  "Psychedelics",
  "Other",
];

const ServiceRequestForm = () => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [uploadedFileUrl, setUploadedFileUrl] = useState("");
  const [loading, setLoading] = useState(false); // Loading state for data fetching and form submission
  const token = localStorage.getItem("token");
  const header = { authorization: `Bearer ${token}` };

  // Fetch user data from localStorage and populate the form
  const userData = JSON.parse(localStorage.getItem("user")) || {}; // Get user from localStorage

  // Initialize form values with data from localStorage
  useEffect(() => {
    form.setFieldsValue({
      firstName: userData.firstName || "",
      lastName: userData.lastName || "",
      branch: userData.branch || "",
      levelOfOperator: userData.levelOfOperator || "",
      state: userData.state || "",
      city: userData.city || "",
      transitionDate: userData.transitionDate
        ? dayjs(userData.transitionDate)
        : null,
    });
  }, [userData, form]);

  // Initialize API mutations
  const [uploadFile] = useUploadFileMutation();
  const [submitRequest] = useSubmitRequestMutation();

  // Handle file upload
  const handleFileChange = ({ file, fileList }) => {
    if (file.status === "done") {
      message.success(`${file.name} file uploaded successfully`);
    } else if (file.status === "error") {
      message.error(`${file.name} file upload failed.`);
    }
    setFileList(fileList);
  };

  const beforeUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        `${baseUrl}${serviceRequestFileUploadApi}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            ...header, // Include additional headers if required
          },
        }
      );
      console.log(response);
      const fileUrl = response.data; // Assuming the backend responds with a 'url' field
      setUploadedFileUrl(fileUrl);
      message.success(`${file.name} uploaded successfully`);

      // Update the fileList manually
      setFileList([{ ...file, status: "done", url: fileUrl }]);
    } catch (error) {
      message.error(`${file.name} file upload failed.`);
    }
    // Prevent Ant Design's default upload behavior
    return false;
  };

  // Handle form submission
  const handleSubmit = async (values) => {
    setLoading(true);

    // Upload the file and get the URL
    try {
      // let fileUrl = null
      // if (fileList.length > 0) {
      //   const fileResponse = await uploadFile(fileList[0]) // Upload the first file
      //   fileUrl = fileResponse.data?.url // Get file URL from the response
      // }

      // Prepare form data payload
      const formData = {
        ...values,
        fileUrl: uploadedFileUrl,
        location: "xyz",
      };
      console.log(formData);

      // Submit the service request data
      const response = await submitRequest(formData);

      if (response.data) {
        message.success("Service request submitted successfully!");
        console.log(response);
        form.resetFields();
      } else {
        message.error("Failed to submit service request");
      }
    } catch (error) {
      message.error("Error submitting request");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="service-request-form">
      <Title level={2}>Service Request Form</Title>
      <Divider />
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        className="form-container"
      >
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[{ required: true, message: "First Name is required" }]}
            >
              <Input placeholder="Enter your first name" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[{ required: true, message: "Last Name is required" }]}
            >
              <Input placeholder="Enter your last name" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Branch"
              name="branch"
              rules={[{ required: true, message: "Branch is required" }]}
            >
              <Input placeholder="Branch" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Level of Operator"
              name="levelOfOperator"
              rules={[
                { required: true, message: "Operator level is required" },
              ]}
            >
              <Input placeholder="Operator Level" />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label="Are you currently employed?"
              name="isCurrentlyEmployed"
              rules={[
                { required: true, message: "Employment status is required" },
              ]}
            >
              <Radio.Group>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Date of Transition / ETS Date"
              name="transitionDate"
              rules={[{ required: true, message: "Date is required" }]}
            >
              <DatePicker style={{ width: "100%" }}/>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Upload DD214"
              name="dd214"
              valuePropName="fileList"
              getValueFromEvent={(e) => e?.fileList}
            >
              <Upload
                name="file"
                beforeUpload={beforeUpload}
                onChange={handleFileChange}
                fileList={fileList}
                maxCount={1}
                // listType='text'
              >
                <Button icon={<UploadOutlined />}>Upload File</Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Type of Support"
              name="supportType"
              rules={[{ required: true, message: "Support type is required" }]}
            >
              <Select
                placeholder="Select support type"
                options={supportOptions.map((type) => ({
                  value: type,
                  label: type,
                }))}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Health Support Needed"
              name="mentalHealthSupportType"
            >
              <Select
                placeholder="Select health support"
                options={mentalHealthOptions.map((type) => ({
                  value: type,
                  label: type,
                }))}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="State"
              name="state"
              rules={[{ required: true, message: "State is required" }]}
            >
              <Input placeholder="State" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="City"
              name="city"
              rules={[{ required: true, message: "City is required" }]}
            >
              <Input placeholder="City" />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item label="Comments" name="comment">
              <Input.TextArea
                placeholder="Enter your comments here"
                rows={4} // Adjust the number of rows as needed
              />
            </Form.Item>
          </Col>
        </Row>
        <Paragraph className="form-note">
          <strong>Note:</strong> My360Tribe does not provide mental health
          counseling/services, financial, or legal advice.
        </Paragraph>
        <div className="form-actions">
          <Button
            type="primary"
            htmlType="submit"
            loading={loading} // Add loading spinner to the button
          >
            Submit
          </Button>
          <Button htmlType="button" onClick={() => form.resetFields()}>
            Reset
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ServiceRequestForm;
