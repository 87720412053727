import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Divider, Button, message, Spin } from "antd";
import BarChart from "../../../Components/Charts/Barchart";
import LineChart from "../../../Components/Charts/LineChart";
import DynamicTable from "../../../Components/UIKit/Table";
import "./index.css";
import {
  useGetAllUsersQuery,
  useDeleteUserMutation,
  useGetUserStatsQuery,
} from "../../../RtkQuery/users/usersApi";
import {
  useApproveOperatorMutation,
  useGetRequestStatsQuery,
} from "../../../RtkQuery/ServiceRequest";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../../Components/UIKit/SearchBar";

const { Title } = Typography;

// Functional Components for SVGs
const ApproveIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    height="18px"
    width="18px"
  >
    <path
      fillRule="evenodd"
      d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
      clipRule="evenodd"
    />
  </svg>
);

const FolderIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    height="18px"
    width="18px"
  >
    <path d="M19.906 9c.382 0 .749.057 1.094.162V9a3 3 0 0 0-3-3h-3.879a.75.75 0 0 1-.53-.22L11.47 3.66A2.25 2.25 0 0 0 9.879 3H6a3 3 0 0 0-3 3v3.162A3.756 3.756 0 0 1 4.094 9h15.812ZM4.094 10.5a2.25 2.25 0 0 0-2.227 2.568l.857 6A2.25 2.25 0 0 0 4.951 21H19.05a2.25 2.25 0 0 0 2.227-1.932l.857-6a2.25 2.25 0 0 0-2.227-2.568H4.094Z" />
  </svg>
);

const AdminDashboard = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const [barChartData, setBarChartData] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const { data, isLoading, error, refetch } = useGetAllUsersQuery(
    { search: searchTerm },
    { refetchOnMountOrArgChange: true }
  );

  const { data: userStats, isLoading: gettingStats } = useGetUserStatsQuery({
    timeFrame: "monthly",
  });

  const [approveOperator, { isLoading: approving }] =
    useApproveOperatorMutation();
  const navigate = useNavigate();

  const handleApprove = async (id) => {
    try {
      await approveOperator(id).unwrap();
      message.success("User Approved!");
      refetch();
    } catch (err) {
      message.error(`Approval failed: ${err?.message || "Unknown error"}`);
    }
  };

  // Role mapping object
  const roleMapping = {
    admin: "Admin",
    peerAmbassador: "Peer Ambassador",
    concierge: "Concierge",
    operator: "Operator",
    intakeCare: "Intake",
  };

  const columns = [
    { title: "First Name", dataIndex: "firstName", key: "firstName" },
    { title: "Last Name", dataIndex: "lastName", key: "lastName" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Contact", dataIndex: "phoneNumber", key: "phoneNumber" },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (role) => roleMapping[role] || role, 
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <div style={{ display: "flex", gap: "1rem" }}>
          {record.approvedStatus === "pending" && (
            <Button
              type="primary"
              shape="circle"
              icon={<ApproveIcon />}
              onClick={() => handleApprove(record._id)}
            />
          )}
          <Button
            type="primary"
            shape="circle"
            icon={<FolderIcon />}
            onClick={() => navigate(`/admin_userDetails/${record._id}`)}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (userStats) {
      console.log("userStats:", userStats); // Debug log
      const barData = Object.keys(userStats.months).map((month) => ({
        month: new Date(2024, month - 1).toLocaleString("default", {
          month: "short",
        }),
        operator: userStats.months[month].operator,
        concierge: userStats.months[month].concierge,
        peerAmbassador: userStats.months[month].peerAmbassador,
        intakeCare: userStats.months[month].intakeCare,
      }));

      // console.log(barData);
      setBarChartData(barData);

      const lineData = Object.keys(userStats.months).map((month) => ({
        month: new Date(2024, month - 1).toLocaleString("default", {
          month: "short",
        }),
        requests: userStats.months[month].requests,
      }));

      // console.log(lineData);
      setLineChartData(lineData);
    }
  }, [userStats]);

  useEffect(() => {
    const fData = data?.filter((user) => user.role !== "superAdmin") || [];
    setFilteredData(fData);
  }, [data]);

  return (
    <div className="super-admin-dashboard">
      <Title level={2}>Dashboard</Title>
      <Divider />
      {gettingStats ? (
        <Spin
          tip="Loading..."
          style={{ display: "block", marginTop: "16px" }}
        />
      ) : (
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <BarChart
              data={barChartData}
              xKey="month"
              bars={[
                { key: "operator", fill: "#054a91" },
                { key: "concierge", fill: "#3e7cb1" },
                { key: "peerAmbassador", fill: "#81a4cd" },
                { key: "intakeCare", fill: "#dbe4ee" },
              ]}
              title="Monthly Registrations by Role"
            />
          </Col>
          <Col span={12}>
            <LineChart
              data={lineChartData}
              xKey="month"
              lineKey="requests"
              lineColor="#1890ff"
              title="Monthly Services Requested"
            />
          </Col>
        </Row>
      )}
      <div style={{ marginTop: "2rem" }}>
        <Title level={3}>Registration Requests</Title>
        <Divider />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            marginBottom: "24px",
          }}
        >
          <h3>Users</h3>
          <div>
            <SearchBar placeholder="Search users..." onSearch={setSearchTerm} />
          </div>
        </div>
        {isLoading ? (
          <Spin
            tip="Loading..."
            style={{ display: "block", marginTop: "16px" }}
          />
        ) : (
          <DynamicTable columns={columns} data={filteredData} />
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;
