import React, { useState, useEffect } from "react";
import { Layout, Menu, Button } from "antd";
import {
  DashboardOutlined,
  UserOutlined,
  FileSearchOutlined,
  LogoutOutlined,
  RightOutlined,
  LeftOutlined,
  // DashboardOutlined,
  UserAddOutlined,
  // LogoutOutlined,
  SolutionOutlined,
  MessageOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import "./index.css";

const { Header, Sider, Content } = Layout;

const WrapperLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [userRole, setUserRole] = useState(""); // Assuming the role is stored here
  const location = useLocation();
  const navigate = useNavigate();
  const [isApproved, setIsApproved] = useState("");
  const [formStatus, setFormStatus] = useState(
    localStorage.getItem("formStatus")
  );

  useEffect(() => {
    // You can fetch user role from context, Redux, or an API call
    const role = localStorage.getItem("role"); // For example, using localStorage
    setUserRole(role);
    const approvalStatus = localStorage.getItem("approvedStatus");
    setIsApproved(approvalStatus);
  }, []);

  useEffect(() => {
    setFormStatus(localStorage.getItem("formStatus"));
  }, [formStatus]);

  // Define role-based menu items
  const operatorMenuItems =
    isApproved === "approved"
      ? [
          {
            key: "/operator_dashboard",
            icon: <DashboardOutlined />,
            label: "Dashboard",
          },
          {
            key: "/service_request_form",
            icon: <FileSearchOutlined />,
            label: "Request Service",
          },

          {
            key: "/operator_chats",
            icon: <MessageOutlined/>,
            // icon: <BsChatSquareText />,
            label: " Chats",
          },

          {
            key: "/operator_profile",
            icon: <UserOutlined />,
            label: "Profile",
          },

          { key: "/logout", icon: <LogoutOutlined />, label: "Logout" },
        ]
      : [
          {
            key: "/operator_dashboard",
            icon: <DashboardOutlined />,
            label: "Dashboard",
          },
          {
            key: "/operator_profile",
            icon: <UserOutlined />,
            label: "Profile",
          },
          { key: "/logout", icon: <LogoutOutlined />, label: "Logout" },
        ];

  const intakecareMenuItems = [
    {
      key: "/intakeCare_dashboard",
      icon: <DashboardOutlined />,
      label: "Dashboard",
    },
    {
      key: "/signup_requests",
      icon: <SolutionOutlined/>,
      // icon: <MdOutlinePendingActions />,
      label: "Operators",
    },
    {
      key: "/intakeCare_chats",
      // icon: <BsChatSquareText />,
      icon: <MessageOutlined/>,
      label: "Chats",
    },
    {
      key: "/intakeCare_profile",
      icon: <UserOutlined />,
      label: "Profile",
    },
    { key: "/logout", icon: <LogoutOutlined />, label: "Logout" },
  ];

  const conciergeMenuItems = [
    {
      key: "/concierge_dashboard",
      icon: <DashboardOutlined />,
      label: "Dashboard",
      disabled: formStatus !== "approved",
    },
    {
      key: "/operators",
      icon: <SolutionOutlined />,
      label: "Operators",
      disabled: formStatus !== "approved",
    },
    {
      key: "/concierge_chats",
      // icon: <BsChatSquareText />,
      icon:<MessageOutlined/>,
      label: "Chats",
      disabled: formStatus !== "approved",
    },
    {
      key: "/concierge_profile",
      icon: <UserOutlined />,
      label: "Profile",
      disabled: formStatus !== "approved",
    },
    { key: "/logout", icon: <LogoutOutlined />, label: "Logout" },
  ];
  const serviceProviderMenuItems = [
    {
      key: "/servicePartner_dashboard",
      icon: <DashboardOutlined />,
      label: "Dashboard",
      disabled: formStatus !== "approved",
    },
    {
      key: "/servicePartner_chats",
      // icon: <BsChatSquareText />,
      icon: <MessageOutlined/>,
      label: "Chats",
      disabled: formStatus !== "approved",
    },
    {
      key: "/servicePartner_profile",
      icon: <UserOutlined />,
      label: "Profile",
      disabled: formStatus !== "approved",
    },
    { key: "/logout", icon: <LogoutOutlined />, label: "Logout" },
  ];

  const peerAmbassadorMenuItems = [
    {
      key: "/peerAmbassador_dashboard",
      icon: <DashboardOutlined />,
      label: "Dashboard",
      disabled: formStatus !== "approved", // Disable if formStatus is not approved
    },
    {
      key: "/peerAmbassador_chats",
      icon:<MessageOutlined/>,
      label: "Chats",
      disabled: formStatus !== "approved", // Disable if formStatus is not approved
    },
    {
      key: "/peerAmbassador_profile",
      icon: <UserOutlined />,
      label: "Profile",
      disabled: formStatus !== "approved", // Disable if formStatus is not approved
    },
    {
      key: "/logout",
      icon: <LogoutOutlined />,
      label: "Logout",
      disabled: false, // Logout should always be enabled
    },
  ];

  const superAdminMenuItems = [
    {
      key: "/superAdmin_dashboard",
      icon: <DashboardOutlined />,
      label: "Dashboard",
    },
    {
      key: "/superAdmin_createUser",
      icon: <UserAddOutlined />,
      label: "Create User",
    },
    {
      key: "/superAdmin_requests",
      icon: <SolutionOutlined />,
      label: "Requests",
    },
    {
      key: "/superAdmin_applications",
      icon: <ProfileOutlined />,
      label: "Applications",
    },
    {
      key: "/superAdmin_chats",
      icon: <MessageOutlined />,
      label: "Chats",
    },
    {
      key: "/superAdmin_profile",
      icon: <UserOutlined />,
      label: "Profile",
    },
    {
      key: "/logout",
      icon: <LogoutOutlined />,
      label: "Logout",
    },
  ];

  const adminMenuItems = [
    {
      key: "/admin_dashboard",
      icon: <DashboardOutlined />,
      label: "Dashboard",
    },
    {
      key: "/admin_createUser",
      icon: <UserAddOutlined />,
      label: "Create User",
    },
    {
      key: "/admin_requests",
      icon: <SolutionOutlined />,
      label: "Requests",
    },
    {
      key: "/admin_applications",
      icon: <ProfileOutlined />,
      label: "Applications",
    },
    {
      key: "/admin_chats",
      icon: <MessageOutlined />,
      label: "Chats",
    },
    {
      key: "/admin_profile",
      icon: <UserOutlined />,
      label: "Profile",
    },
    { key: "/logout", icon: <LogoutOutlined />, label: "Logout" },
  ];

  // Based on the user's role, select the appropriate menu items
  let selectedMenuItems = [];
  switch (userRole) {
    case "operator":
      selectedMenuItems = operatorMenuItems;
      break;
    case "intakeCare":
      selectedMenuItems = intakecareMenuItems;
      break;
    case "concierge":
      selectedMenuItems = conciergeMenuItems;
      break;
    case "servicePartner":
      selectedMenuItems = serviceProviderMenuItems;
      break;
    case "peerAmbassador":
      selectedMenuItems = peerAmbassadorMenuItems;
      break;
    case "superAdmin":
      selectedMenuItems = superAdminMenuItems;
      break;
    case "admin":
      selectedMenuItems = adminMenuItems;
      break;
    default:
      selectedMenuItems = []; // If no role is set, show no menu items
  }

  const handleMenuClick = ({ key }) => {
    if (key === "/logout") {
      console.log("Logging out...");
      localStorage.clear();
      navigate("/login");
    } else {
      navigate(key);
    }
  };
  console.log(location.pathname);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        className="main-sidenav"
        trigger={null} // Disable the default trigger
        collapsible
        collapsed={collapsed}
        width={250}
        style={{
          position: "fixed",
          height: "100vh",
          left: 0,
          top: 0,
          zIndex: 10,
        }}
      >
        <div className="logo">
          {collapsed ? (
            <img src="./logo_white.png" width="30px" alt="logo" />
          ) : (
            "My360Tribe"
          )}
          {/* Custom collapse button */}
          <Button
            type="text"
            shape="circle"
            icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            className="collapse-button"
          />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          items={selectedMenuItems}
          onClick={handleMenuClick}
          selectedKeys={[location.pathname]}
        />
      </Sider>
      <Layout style={{ marginLeft: collapsed ? 80 : 250 }}>
        <Header className="main-header"></Header>
        <Content
          style={{ margin: "16px", background: "#F8F8F8", padding: "16px" }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default WrapperLayout;
