import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const BarChartComponent = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={350}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="operator" fill="#054a91" />
        <Bar dataKey="concierge" fill="#3e7cb1" />
        <Bar dataKey="peerAmbassador" fill="#81a4cd" />
        <Bar dataKey="intakeCare" fill="#dbe4ee" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartComponent;
