import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from '../../Apis';

export const chatApi = createApi({
  reducerPath: 'chatApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: headers => {
      const token = localStorage.getItem('token')
      headers.set('authorization', `Bearer ${token}`)
      return headers
    }
  }),
  endpoints: (builder) => ({
    // Fetch chat history by chat ID
    fetchChatHistory: builder.query({
      query: (chatId) => `history/${chatId}`,
    }),

    // Send a message
    sendMessage: builder.mutation({
      query: ({ chatId, text }) => ({
        url: `send`,
        method: 'POST',
        body: { chatId, text },
      }),
    }),

    // Fetch all users
    fetchUsers: builder.query({
      query: () => `users`,
    }),

    // Create a new chat
    createChat: builder.mutation({
      query: ({ sender, recipient,message }) => ({
        url: `/chats`,
        method: 'POST',
        body: { sender, recipient, message },
      }),
    }),

    // Get a conversation between two users
    getConversationBetweenUsers: builder.query({
      query: ({ senderId, recipientId }) => ({
        url: `/chats/${senderId}/${recipientId}`,
        method: 'GET',
        // params: { senderId, recipientId },
      }),
    }),

    // Find all chats for the current user
    findChatsForCurrentUser: builder.query({
      query: (userId) => `chats/user/${userId}?groupByOtherUser=true`,
    }),
  }),
});

export const {
  useFetchChatHistoryQuery,
  useSendMessageMutation,
  useFetchUsersQuery,
  useCreateChatMutation,
  useGetConversationBetweenUsersQuery,
  useFindChatsForCurrentUserQuery,
} = chatApi;
