import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

const PrivateRoute = ({ element, allowedRoles }) => {
  const user = localStorage.getItem('role');
  const token = localStorage.getItem('token');
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!token) {
      localStorage.clear();
      navigate('/login', { replace: true });
    }
  }, [token, navigate]);

  if (!allowedRoles.includes(user)) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return element;
};

export default PrivateRoute;
