import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Divider,
  Typography,
  Spin,
  Alert,
  Tag,
  Table,
  message,
} from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { useGetRequestsQuery } from "../../../RtkQuery/ServiceRequest";
import dayjs from "dayjs";
import SearchBar from "../../../Components/UIKit/SearchBar";
import ConfirmationModal from "../../../Components/Modals/ConfirmationModals";
import { useDeleteRequestMutation } from "../../../RtkQuery/ServiceRequest";

const AdminRequests = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const { Title, Paragraph } = Typography;
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  const [deleteRequest, { isLoading: deleting }] = useDeleteRequestMutation();

  // Fetch requests using RTK Query
  const { data, error, isLoading, refetch } = useGetRequestsQuery({
    page: pagination.current,
    limit: pagination.pageSize,
    search: searchTerm,
  },{refetchOnMountOrArgChange:true});

  const handleDelete = async (id) => {
    try {
      const response = await deleteRequest(id).unwrap();
      if (response) {
        message.success("Request Deleted!");
        refetch();
      }
    } catch (err) {
      // Handle error
      message.error("Failed to delete the Request.");
    }
  };

  // Table columns
  const columns = [
    {
      title: "Support Type",
      dataIndex: "supportType",
      key: "supportType",
    },
    {
      title: "Health Support Type",
      dataIndex: "mentalHealthSupportType",
      key: "mentalHealthSupportType",
    },
    {
      title: "Requestee Name",
      key: "requesteeName",
      render: (_, record) =>
        `${record.operator?.firstName || ""} ${
          record.operator?.lastName || ""
        }`.trim(),
    },
    {
      title: "Request Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => dayjs(createdAt).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "Status",
      dataIndex: "approvedStatus",
      key: "approvedStatus",
      render: (_, record) => (
        <Tag
          style={{
            color:
              record.approvedStatus === "pending" || record.status === "pending"
                ? "orange"
                : record.approvedStatus === "approved" ||
                  record.status === "completed"
                ? "green"
                : "red",
          }}
        >
          {record.approvedStatus === "pending"
            ? "Pending"
            : record.approvedStatus === "approved" &&
              record.status === "pending"
            ? "In progress"
            : record.status === "completed"
            ? "Completed"
            : "Rejected"}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div style={{ display: "flex", gap: "1rem" }}>
          <Button
            type="primary"
            shape="circle"
            icon={<EyeOutlined />}
            onClick={() => navigate(`/admin_requestDetails/${record._id}`)}
          />
          <ConfirmationModal
            type="delete"
            message="Are you sure you want to delete this request?"
            onConfirm={() => handleDelete(record._id)}
          />
        </div>
      ),
    },
  ];
  const handleTableChange = (pagination) => {
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  return (
    <div style={{ padding: "16px" }}>
      <Title level={2}>Requests</Title>
      <Divider />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          marginBottom: "24px",
        }}
      >
        <div>
          <h3>Requests</h3>
        </div>
        <div>
          <SearchBar
            placeholder="Search requests..."
            onSearch={(term) => setSearchTerm(term)}
          />
        </div>
      </div>

      {isLoading && (
        <div style={{ textAlign: "center", padding: "24px 0" }}>
          <Spin
            tip="Loading..."
            style={{ display: "block", marginTop: "16px" }}
          />
        </div>
      )}

      {error && (
        <Alert
          message="Error"
          description="An error occurred while fetching requests. Please try again later."
          type="error"
          showIcon
          style={{ marginBottom: "16px" }}
        />
      )}

      {!isLoading && !error && data?.data?.length === 0 && (
        <Paragraph style={{ textAlign: "center", color: "gray" }}>
          No requests found.
        </Paragraph>
      )}

      {!isLoading && !error && data?.data?.length > 0 && (
        <Table
          style={{ borderRadius: "10px", border: "2px solid #f0f0f0" }}
          columns={columns}
          dataSource={data.data} // Use state-based data
          rowKey="_id" // Use a unique key for rows
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: data?.total || 0,
          }}
          onChange={handleTableChange}
          loading={isLoading}
        />
      )}
    </div>
  );
};

export default AdminRequests;
