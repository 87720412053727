import React from "react";
import {
  Card,
  Button,
  Typography,
  Row,
  Col,
  Spin,
  Alert,
  Tag,
  Table,
} from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useGetSingleRequestQuery } from "../../../RtkQuery/ServiceRequest"; // Import the getSingleRequest API hook
import "./index.css";
import DualListCard from "../../../Components/UIKit/DualListCard";
import dayjs from "dayjs";
import FileViewerModal from "../../../Components/Modals/FileViewer";
import { baseUrl } from "../../../Apis";

const { Title, Text } = Typography;

const OperatorRequestDetailsPage = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Get request ID from the URL
  const { data, isLoading, refetch, error } = useGetSingleRequestQuery(id, {
    refetchOnMountOrArgChange: true,
  });
  // const user = JSON.parse(localStorage.getItem('user')) || {}

  // Fetch request details using the API hook
  // const { data, error, isLoading } = useGetRequestsQuery({ page: 1, limit: 10 })

  if (isLoading) {
    return (
      <Spin tip="Loading..." style={{ display: "block", marginTop: "16px" }} />
    );
  }

  if (error) {
    return (
      <Alert
        message="Error"
        description="Failed to load request details"
        type="error"
      />
    );
  }

  return (
    <div className="details-container">
      {/* Back Button */}
      <Button
        className="back-button"
        icon={<LeftOutlined />}
        onClick={() => navigate(-1)}
      >
        Back
      </Button>
      {data && (
        <Card
          className="details-card"
          title={
            <Title level={4} style={{ margin: "0.5rem 0" }}>
              Request Details
            </Title>
          }
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Text strong>ID: </Text>
              <Text>{data._id}</Text>
            </Col>
            <Col xs={24} sm={12}>
              <Text strong>Support Type: </Text>
              <Text>{data.supportType}</Text>
            </Col>
            <Col xs={24} sm={12}>
              <Text strong>Health Support Type: </Text>
              <Text>{data.mentalHealthSupportType}</Text>
            </Col>
            <Col xs={24} sm={12}>
              <Text strong>Request Time: </Text>
              <Text>
                {data.createdAt
                  ? dayjs(data.createdAt).format("YYYY-MM-DD HH:mm A")
                  : ""}
              </Text>
            </Col>
            <Col xs={24} sm={12}>
              <Text strong>Employment Status: </Text>
              <Text>
                {data.isCurrentlyEmployed === true ? "Employed" : "Unemployed"}
              </Text>
            </Col>
            <Col span={12}>
              <Text strong>Status:</Text>{" "}
              <Tag
                style={{
                  color:
                    data.approvedStatus === "pending" ||
                    data.status === "pending"
                      ? "orange"
                      : data.approvedStatus === "approved" ||
                        data.status === "completed"
                      ? "green"
                      : "red",
                }}
              >
                {data.approvedStatus === "pending"
                  ? "Pending"
                  : data.approvedStatus === "approved" &&
                    data.status === "pending"
                  ? "In progress"
                  : data.status === "completed"
                  ? "Completed"
                  : "Rejected"}
              </Tag>
            </Col>
            {data.fileUrl && (
              <Col xs={24} sm={12}>
                <Text strong>Uploaded File: </Text>
                <FileViewerModal fileUrl={baseUrl + "/" + data.fileUrl} />
              </Col>
            )}
          </Row>
          <Row style={{ marginTop: "1rem" }}>
            <Col span={24}>
              <Text strong>Comments: </Text>
              <Text>{data.comment}</Text>
            </Col>
          </Row>
        </Card>
      )}

      {/* <DualListCard
        listOne={data.assignedConcierge}
        listTwo={data.assignedPeerAmbassador}
        titleOne={'Assigned Concierge'}
        titleTwo={'Assigned Peer Ambassador'}
        chatUrl = {'/operator_chats'}
      /> */}
    </div>
  );
};

export default OperatorRequestDetailsPage;
